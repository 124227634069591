const titleStyles = {
    color: "secondaryColor",
    fontSize: 20,
    fontWeight: 700
};

const buttonStyles = {
    _active: { bgColor: "mainColor", borderColor: "buttonColor", border: "2px" },
    py: 3,
    borderColor: "buttonColor",
    borderWidth: "3px",
    bgColor: "mainColor",
    px: { base: 3, lg: 4 },
    color: "buttonColor",
    borderRadius: "5px",
    _hover: { bgColor: "mainColor", color: "buttonColor" },
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "interFont"

};

const modalFooterButtonStyles = {
    bgColor: 'thirdButtonColor',
    color: 'secondaryTextColor',
    mr: 3
};

const modalFooterCancelButtonStyles = {
    bgColor: "cancelColor",
    color: "secondaryTextColor"
}

export { titleStyles, buttonStyles, modalFooterButtonStyles, modalFooterCancelButtonStyles }
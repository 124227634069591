
const mainBoxStyles = {
    pt: 40,
    pb: 10,
    minHeight: "100vh",
    fontFamily: "ubuntuFont"
}

const tabsStyles = {
    variant: "unstyled"
}

const tabListStyles = {
    py: 0,
    px: 8
}

const tabStyles = {
    _focus: { outline: "none" },
    border: "1px",
    borderColor: "secondaryColor",
    backgroundColor: "transparent",
    borderRadiusTop: "20px",
    _hover: { color: "secondaryColor" },
    color: "secondaryColor",
    _selected: { color: "secondaryTextColor", backgroundColor: "secondaryColor" },
    fontSize: 15,
    fontWeight: 500,
    px: { base: "10px", lg: "30px" }
}

const tabPanelsStyles = {
    px: 8
}

export { mainBoxStyles, tabsStyles, tabListStyles, tabStyles, tabPanelsStyles }
const mainBoxStyles = {
    w: "1200px",
    fontFamily: "ubuntuFont",
    pt: "30px",
    marginX: "auto",
    pb: 16
}

const changePasswordBoxStyles = {
    mb: "80px",
    borderBottom: "1px",
    borderColor: "mainColor",
    py: 10
}

export { mainBoxStyles, changePasswordBoxStyles }
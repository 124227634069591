import React, { useState, useMemo, useEffect } from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Button, Text, Card, Image, Stack, CardBody, Heading, CardFooter, Flex, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { Link as RouterLink } from "@chakra-ui/react"
import { Link } from 'react-router-dom'
import TemplateList from 'components/TemplateList'
import DecisionCopyTable from "components/DecisionCopyTable"
import { useTranslation } from 'react-i18next';
import { mainBoxStyles, tabsStyle, tabListStyle, tabStyle } from "./styles"
import { useCurrentDecisionsContext } from 'features/decisions/contexts/decisions-provider';
import {
    useGlobalFilter,
    useSortBy,
    useTable,
    useExpanded,
    usePagination,
    useAsyncDebounce
} from 'react-table';
import HubspotModal from 'components/HubspotModal';

export default function CreateDecision() {
    const [textToFilter, setTextToFilter] = useState("")
    const { tableDecisionsHostingData, convertDate } = useCurrentDecisionsContext()
    const { dataOfTable } = tableDecisionsHostingData
    const [t] = useTranslation('CreateDecision');
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);

    const handleButtonClick = () => {
        setIsFormVisible(true);
    };

    const [tableState, setTableState] = useState(() => {
        const savedState = localStorage.getItem('decisionsCopyTable');
        return savedState ? JSON.parse(savedState) : {};
    });

    const ourGlobalFilterFunction = (rows, ids, query) => {
        return rows.filter(
            (row) =>
                (row.values["expander"] && query && row.values["expander"].toLowerCase().includes(query.toLowerCase())) ||
                (row.values["group-name"] && query && row.values["group-name"].toLowerCase().includes(query.toLowerCase()))
        );
    };

    const columns = useMemo(
        () => [
            {
                Header: "Decision",
                accessor: "title",
                id: 'expander',
                Cell: ({ row }) => (
                    <>
                        {dataOfTable[row.id]["current-mode"] !== "Closed" ?
                            <Flex gap={2} p={0} m={0} align="center" textAlign="left" color="baseLinkColor" _hover={{ cursor: "pointer" }}>
                                <Link as={RouterLink} _hover={{ textDecoration: "none", color: "secondaryColor" }} zIndex={2}>
                                    {dataOfTable[row.id].title}{" "}
                                </Link>
                                {dataOfTable[row.id].info && (
                                    <Box {...row.getToggleRowExpandedProps()} fontSize={14} color="mainTextColor" _hover={{ cursor: "pointer", color: "mainTextColor" }}>
                                        {row.isExpanded ? "(" + t("less") + ")" : "(" + t("more") + ")"}
                                    </Box>
                                )}
                            </Flex>
                            :
                            <Flex gap={2} p={0} m={0} align="center" textAlign="left">
                                <Box>{dataOfTable[row.id].title}{" "}</Box>
                                {dataOfTable[row.id].info && <Box {...row.getToggleRowExpandedProps()} fontSize={14}>
                                    {row.isExpanded ? "(" + t("less") + ")" : "(" + t("more") + ")"}
                                </Box>}
                            </Flex>}
                    </>
                ),
            },
            {
                Header: "Group",
                accessor: "group-name",
            },
            {
                Header: "Updated at",
                accessor: "updated-at",
                Cell: ({ row }) => {
                    const dateFormatted = convertDate(dataOfTable[row.id]["updated-at"])
                    return (
                        <>
                            {Array.isArray(dateFormatted) ?
                                <Box>
                                    <Box>{dateFormatted[0]} {dateFormatted[1]}</Box>
                                </Box> :
                                <Box>{dateFormatted}</Box>}
                        </>
                    )
                },
            }
        ],
        [],
    );

    const tableInstance = useTable(
        {
            columns: columns,
            data: dataOfTable,
            initialState: { ...tableState, pageIndex: 0, pageSize: 6 },
            globalFilter: ourGlobalFilterFunction,
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination
    );

    const {
        setGlobalFilter
    } = tableInstance;

    const onChange = useAsyncDebounce((value) => {
        setTextToFilter(value);
        setGlobalFilter(value || undefined);
    }, 300);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: '6224498',
                    formId: 'a14328b1-0fc3-40ad-b6dc-f0d4c59facb9',
                    target: '#hubspotForm'
                })
            }
        });
    }, []);

    return (
        <Box {...mainBoxStyles} >
            <Card
                direction={{ base: 'column', sm: 'row' }}
                overflow='hidden'
                variant='outline'
                py={4}
                px={8}
            >
                <Flex align="center">
                    <Image
                        borderRadius="100%"
                        src='create_decision.png'
                        w="150px"
                        h="150px"
                        mr={8}
                    />
                    <Stack gap={0}>
                        <CardBody py={0} mb={0}>
                            <Heading size='md' color="secondaryColor">{t("create-a-decision")}</Heading>
                            <Text>
                                {t('introduction-to-create-a-decision')}
                            </Text>
                            <Text>
                                {t("need-something-special")}
                            </Text>
                        </CardBody>
                        <CardFooter py={0} mt={0}>
                            {/*<Button onClick={handleButtonClick} _active={{ bgColor: "mainColor", borderColor: "buttonColor", border: "2px" }} py={5} borderColor="buttonColor" borderWidth="3px" bgColor="mainColor" px={{ base: 3, lg: 6 }} color="buttonColor" borderRadius="5px" _hover={{ bgColor: "mainColor", color: "buttonColor" }} fontSize={16} fontWeight={600} fontFamily="interFont">
                                {t("book-a-consultation")}
                            </Button>*/}
                            <HubspotModal />
                        </CardFooter>
                    </Stack>
                </Flex>
            </Card>
            <Tabs {...tabsStyle}>
                <Flex justifyContent="space-between" alignItems="flex-end" w="full">
                    <TabList {...tabListStyle}>
                        <Tab {...tabStyle} borderTopLeftRadius="10px" >
                            {t("from-template")}
                        </Tab>
                        <Tab {...tabStyle} borderTopRightRadius="10px">
                            {t("copy-existing")}
                        </Tab>
                    </TabList>
                    <Box mb={4}>
                        <InputGroup>
                            <InputLeftElement children={<Image src={'search.png'} ml={5} />} />
                            <Input w="500px" value={textToFilter || ""}
                                onChange={(e) => {
                                    setTextToFilter(e.target.value);
                                    onChange(e.target.value);
                                }} placeholder='Search' bgColor="secondaryBackgroundColor" borderColor="secondaryColor" borderRadius="25px" _hover={{ borderColor: "secondaryColor" }} />
                        </InputGroup>
                    </Box>
                </Flex>
                <TabPanels >
                    <TabPanel p={0}>
                        <TemplateList textToFilter={textToFilter} />
                    </TabPanel>
                    <TabPanel p={0}>
                        <DecisionCopyTable tableInstance={tableInstance} columns={columns} setTableState={setTableState} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box >
    )
}

import {
    Flex,
    Center,
    Button,
    Stack,
    Box,
    Link,
    Text
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom"
import { useTranslation } from 'react-i18next';

const RecoverPasswordDone = () => {
    const [t] = useTranslation('RecoverPasswordDone');

    return (
        <Flex
            flexDirection="column"
            width="100wh"
            height="100vh"
            backgroundColor="mainColor"
            justifyContent="center"
            alignItems="center"
            fontFamily="Inter"
        >
            <Stack
                flexDir="column"
                mb="2"
                justifyContent="center"
                alignItems="center"
            >

                <Box minW={{ md: "468px" }}>
                    <Stack

                        pt={8}
                        pb={16}
                        backgroundColor="formBackground"
                        boxShadow="md"

                    >
                        <Center mb={-1}>
                            <Box color="mainTextColor" fontWeight={700}>{t("forgot-your-password")}</Box>
                        </Center>
                        <Center px={12}>
                            <Text color="mainTextColor" textAlign="center" fontSize={16} fontWeight={400}>{t("we-can-mail-reset-instructions")} <br /> {t("password")}</Text>
                        </Center>
                        <Box w="100%">
                            <Center px={12}>
                                <Button
                                    _hover={{ bgColor: "secondaryButtonColor" }}
                                    _active={{ bgColor: "secondaryButtonColor" }}
                                    my={2}
                                    variant="solid"
                                    color="secondaryTextColor"
                                    bgColor="secondaryButtonColor"
                                    width="full"
                                    h={12}
                                    borderRadius={8}
                                    fontSize={17}
                                    fontWeight={300}
                                    letterSpacing={0.5}
                                >
                                    <Link as={RouterLink} to="/" _hover={{ textDecoration: "none", color: "secondaryTextColor" }}>{t("please-sign-in")}</Link>
                                </Button>
                            </Center>
                        </Box>
                    </Stack>
                </Box>
            </Stack >
        </Flex >
    );
};

export default RecoverPasswordDone;
import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import {
    Text,
    Input,
    Box,
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { inputStyles, boxStyles, textStyles } from "./styles"

export default function GlobalFilter({
    globalFilter,
    setGlobalFilter,
}) {
    const [value, setValue] = useState(globalFilter);
    const [t] = useTranslation('GlobalFilter');

    const onChange = useAsyncDebounce((value) => {
        setValue(value);
        setGlobalFilter(value || undefined);
    }, 300);

    return (
        <Box {...boxStyles}>
            <Text {...textStyles}>{t("search")}</Text>
            <Input
                {...inputStyles}
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
        </Box>
    );
}
import {
  Flex,
  Input,
  Button,
  InputGroup,
  CardBody,
  Text,
  Card,
  Link
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom"
import { inputStyles, chakraStyles, headerTextStyles, flexColumnStyles, errorTextStyles, createDecisionButtonStyles } from "./styles.js"
import { Select as SelectWithSearch } from "chakra-react-select";
import { useState } from "react"
import { getNames, getCode, getCodes, getName } from "country-list";
import moment from "moment-timezone"
import { useCurrentDecisionsContext } from 'features/decisions/contexts/decisions-provider';
import { useTranslation } from 'react-i18next';
import decisionApi from "api/decisions"
import { getErrorMessage } from "utils"
import CreateDecisionModal from "components/CreateDecisionModal/index.js";
import LeftArrow from 'images/leftArrow'

const hasHTML = (str) => {
  const htmlRegex = /<[a-z][\s\S]*>/;

  return htmlRegex.test(str);
}

const timezones = moment.tz.names();

const getTimezonesInFormat = () => {
  return timezones
    .map((timezone) => {
      const currentTime = moment.tz(timezone);
      const offsetHours = currentTime.utcOffset() / 60;
      return { timezone, offsetHours };
    })
    .sort((a, b) => a.offsetHours - b.offsetHours)
    .map(({ timezone, offsetHours }) => (
      {
        value: timezone,
        label: `(GMT${offsetHours >= 0 ? `+${offsetHours}` : offsetHours}) ${timezone}`
      }
    ));
}

const getCountryInFormat = country => {
  return {
    label: country,
    value: getCode(country)
  }
}

const countries = getNames().map((name) => (getCountryInFormat(name)));



const getTimezoneObject = timezone => {
  const currentTime = moment.tz(timezone);
  const offsetHours = currentTime.utcOffset() / 60
  return {
    value: timezone,
    label: `(GMT${offsetHours >= 0 ? `+${offsetHours}` : offsetHours}) ${timezone}`
  }
}

const getCurrentDateInISO8601Format = () => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to the month since months are zero-indexed
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

  return formattedDate;
};

const CreateDecisionForm = () => {

  const { createDecisionData } = useCurrentDecisionsContext()
  const [openModal, setOpenModal] = useState(false)
  const { decisionData } = createDecisionData
  const [t] = useTranslation('CreateDecisionForm');
  const [editDecisionLink, setEditDecisionLink] = useState("")
  const [subdomain, setSubdomain] = useState(decisionData.subdomain ? decisionData.subdomain : "")
  const [title, setTitle] = useState(decisionData.title ? decisionData.title : "")
  const [country, setCountry] = useState(decisionData.country ? getCountryInFormat(getName(decisionData.country)) : getCountryInFormat(getName("CA")))
  const [timezone, setTimezone] = useState(decisionData["time-zone"] ? getTimezoneObject(decisionData["time-zone"]) : getTimezoneObject("UTC"))
  const [openAt, setOpenAt] = useState(getCurrentDateInISO8601Format())
  const [creatingDecision, setCreatingDecision] = useState(false)
  const [errorWithTitle, setErrorWithTitle] = useState(false)
  const [errorWithTitleText, setErrorWithTitleText] = useState("")
  const [errorWithSubdomain, setErrorWithSubdomain] = useState(false)
  const [errorWithSubdomainText, setErrorWithSubdomainText] = useState("")
  const [errorWithTimezone, setErrorWithTimezone] = useState(false)
  const [errorWithTimezoneText, setErrorWithTimezoneText] = useState("")
  const [errorWithCountry, setErrorWithCountry] = useState(false)
  const [errorWithCountryText, setErrorWithCountryText] = useState("")
  const timezonesToDisplay = getTimezonesInFormat()


  const createDecision = () => {
    let errorFound = false

    setErrorWithCountry(false)
    setErrorWithSubdomain(false)
    setErrorWithTitle(false)
    setErrorWithTimezone(false)

    const decisionDataForAPI = {
      decision: {
        id: decisionData.isTemplate ? parseInt(decisionData.id) : parseInt(decisionData["decision-id"]),
        slug: subdomain,
        country: country.value,
        time_zone: timezone.value,
        open_at: openAt,
        title: title
      }
    };

    if (hasHTML(title)) {
      setErrorWithTitleText("The title can't contain HTML")
      setErrorWithTitle(true)
      errorFound = true
    } else if (title.length < 2) {
      setErrorWithTitle(true)
      setErrorWithTitleText("The title needs at least 2 characters")
      errorFound = true
    } else if (title.length > 255) {
      setErrorWithTitleText("The title can't have more than 255 characters")
      setErrorWithTitle(true)
      errorFound = true
    }

    if (subdomain.length < 2) {
      setErrorWithSubdomain(true)
      setErrorWithSubdomainText("The subdomain needs at least 2 characters")
      errorFound = true
    } else if (title.length > 255) {
      setErrorWithSubdomain(true)
      setErrorWithSubdomainText("The subdomain can't have more than 255 characters")
      errorFound = true
    }

    if (!getCodes().includes(country.value)) {
      setErrorWithCountry(true)
      setErrorWithCountryText("The name of the country is not valid")
      errorFound = true
    }

    const timezones = moment.tz.names();

    if (!timezones.includes(timezone.value)) {
      setErrorWithTimezone(true)
      setErrorWithTimezoneText("The timezone is not valid")
      errorFound = true
    }


    if (errorFound) {
      return
    }

    setCreatingDecision(true)
    decisionApi.createDecisions(decisionDataForAPI).then(e => {
      setEditDecisionLink(e.data.links.edit)
      setCreatingDecision(false)
      setOpenModal(true)
    }).catch(e => {
      setCreatingDecision(false)
      e?.data?.errors?.forEach(currentError => {
        switch (currentError.source.pointer) {
          case "/data/attributes/country":
            setErrorWithCountry(true)
            setErrorWithCountryText(getErrorMessage(currentError.detail))
          case "/data/attributes/time-zone":
            setErrorWithTimezone(true)
            setErrorWithTimezoneText(getErrorMessage(currentError.detail))
          case "/data/attributes/title":
            setErrorWithTitle(true)
            setErrorWithTitleText(getErrorMessage(currentError.detail))
          case "/data/attributes/slug":
            setErrorWithSubdomain(true)
            setErrorWithSubdomainText(getErrorMessage(currentError.detail))
        }

      })
    }
    )
  }

  return (
    <Card boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)" w="500px" ml="120px" py={8} px={10}>
      <Link as={RouterLink} to="/create-decisions" textAlign="left" textDecoration="underline" color="secondaryColor" fontWeight={700} _hover={{ color: "secondaryColor" }}>
        <Flex direction="row" align="center">
          <LeftArrow active={true} />
          <Text pt={3} pl={2}>{t("go-back")}</Text>
        </Flex>
      </Link>
      <Text {...headerTextStyles}>
        {decisionData.isTemplate ? `${t("use")}` + " " : `${t("copy")}` + " "}{decisionData.title}{decisionData.isTemplate ? ` ${t("template")}` : ` ${t("decision")}`}
      </Text>
      {decisionData.isTemplate ?
        <Text fontSize={14}>
          {t("your-decision-will-be-a-copy")}
        </Text> :
        <Text fontSize={14}>
          {t("decision-based-on-template")}
        </Text>
      }
      <CardBody px={0} pt={0} mb={0}>
        <InputGroup mb={5} mt={3} >
          <Flex {...flexColumnStyles}>
            <Text color="secondaryColor" fontFamily="ubuntu" fontWeight={500} mb={2}>
              {t("title")} *
            </Text>
            <Input type="text" value={title} onChange={e => setTitle(e.target.value)} {...inputStyles} borderColor={errorWithTitle ? "errorColor" : "inputBorderColor"} />
            {errorWithTitle && <Text {...errorTextStyles}>{errorWithTitleText}</Text>}
          </Flex>
        </InputGroup>
        <InputGroup mb={5}>
          <Flex {...flexColumnStyles}>
            <Text color="secondaryColor" fontFamily="ubuntu" fontWeight={500} mb={0}>
              {t("subdomain-address")} *
            </Text>
            <Text fontSize={14} mb={1}>{t("your-decision-will-be-located-at")} https:://SUBDOMAIN.ethelo.net</Text>
            <Input type="text" value={subdomain} onChange={e => setSubdomain(e.target.value)} {...inputStyles} borderColor={errorWithSubdomain ? "errorColor" : "inputBorderColor"} />
            {errorWithSubdomain && <Text {...errorTextStyles}>{errorWithSubdomainText}</Text>}
          </Flex>
        </InputGroup>
        <InputGroup mb={5}>
          <Flex {...flexColumnStyles} position="relative" zIndex={2}>
            <Text color="secondaryColor" fontFamily="ubuntu" fontWeight={500} mb={2}>
              {t("country")} *
            </Text>
            <SelectWithSearch menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 1 }) }} borderColor={errorWithCountry ? "errorColor" : "inputBorderColor"} chakraStyles={chakraStyles} options={countries} placeholder={t("select-a-country")} value={country} onChange={setCountry} />
            {errorWithCountry && <Text {...errorTextStyles}>{errorWithCountryText}</Text>}
          </Flex>
        </InputGroup>
        <InputGroup mb={5}>
          <Flex {...flexColumnStyles}>
            <Text color="secondaryColor" fontFamily="ubuntu" fontWeight={500} mb={2}>
              {t("time-zone")} *
            </Text>
            <SelectWithSearch borderColor={errorWithTimezone ? "errorColor" : "inputBorderColor"} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 1 }) }} chakraStyles={chakraStyles} options={timezonesToDisplay} placeholder={t("select-a-timezone")} value={timezone} onChange={setTimezone} />
            {errorWithTimezone && <Text {...errorTextStyles}>{errorWithTimezoneText}</Text>}
          </Flex>
        </InputGroup>
        <InputGroup>
          <Flex {...flexColumnStyles}>
            <Text color="secondaryColor" fontFamily="ubuntu" fontWeight={500} mb={2}>
              {t("open-date-and-time")}
            </Text>
            <Input type="datetime-local" value={openAt} onChange={e => setOpenAt(e.target.value)} {...inputStyles} />
          </Flex>
        </InputGroup>
      </CardBody>
      <Button isDisabled={creatingDecision} onClick={() => createDecision()} {...createDecisionButtonStyles}>{creatingDecision ? t("creating-decision") : t("create-decision")}</Button>
      <CreateDecisionModal editDecisionLink={editDecisionLink} openModal={openModal} />
    </Card>
  );
};

export default CreateDecisionForm;

import { bgcolor } from "@mui/system"

const mainBoxStyles = {
    py: 20,
    fontFamily: "ubuntuFont",
    px: 12
}

const headerStyles = {
    color: "secondaryColor",
    fontSize: 20,
    fontWeight: 700,
    fontFamily: "ubuntuFont"
}

const tabsStyle = {
    variant: "unstyled",
    mt: 10,
    h: "5px"
}

const tabListStyle = {
    h: 10,
}

const tabStyle = {
    _focus: { outline: "none" },
    border: "1px",
    borderColor: "secondaryColor",
    backgroundColor: "transparent",
    borderRadiusTop: "20px",
    _hover: { color: "secondaryColor" },
    color: "secondaryColor",
    _selected: { color: "secondaryTextColor", backgroundColor: "secondaryColor" },
    fontSize: 15,
    fontWeight: 500,
    px: { base: "10px", lg: "30px" },
    py: 0

}

export { mainBoxStyles, headerStyles, tabsStyle, tabListStyle, tabStyle }
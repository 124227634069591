import { Box, Grid, GridItem } from '@chakra-ui/react';
import DeleteAccountModal from 'components/DeleteAccountModal';
import ChangePassword from "components/ChangePassword";
import EditProfilePhoto from "components/EditProfilePhoto"
import { mainBoxStyles, changePasswordBoxStyles } from "./styles"

const Home = () => {
    return (
        <Box {...mainBoxStyles}>
            <Grid templateColumns='repeat(5, 1fr)' gap={6}>
                <GridItem colSpan={2}>
                    <EditProfilePhoto />
                </GridItem>
                <GridItem colSpan={3}>
                    <Box {...changePasswordBoxStyles}>
                        <ChangePassword />
                    </Box>
                </GridItem>
                <GridItem colSpan={5}>
                    <DeleteAccountModal />
                </GridItem>
            </Grid>
        </Box>
    );
};

export default Home;
import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Text,
    Box,
    Card,
    Image,
    Stack,
    CardBody,
    Heading,
    CardFooter
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { titleStyles, buttonStyles, modalFooterButtonStyles, modalFooterCancelButtonStyles } from "./styles"

export default function DeleteAccountModal() {
    const [t] = useTranslation('DeleteAccountModal');
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box px={0}>
            <Card
                direction={{ base: 'column', sm: 'row' }}
                variant='outline'
                px={16}
                py={8}
            >
                <Image
                    borderRadius="100%"
                    src='delete_account.png'
                    w="120px"
                    h="120px"
                    my="auto"
                />

                <Stack>
                    <CardBody py={0}>
                        <Heading size='md' {...titleStyles}>{t("delete-my-account")}</Heading>

                        <Text py='0'>
                            {t("we-are-sorry-that-you-have-to-leave-us")}
                        </Text>
                    </CardBody>

                    <CardFooter py={0}>
                        <Button onClick={onOpen} {...buttonStyles}>{t("delete-account")}</Button>
                    </CardFooter>
                </Stack>
            </Card>
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("delete-account")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontWeight='bold' mb='1rem'>
                            {t("are-you-sure-to-delete-account")}
                        </Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button {...modalFooterButtonStyles} onClick={onClose}>
                            {t("close")}
                        </Button>
                        <Button {...modalFooterCancelButtonStyles} onClick={onClose}>
                            {t("delete")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}
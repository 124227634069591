const headerStyles = {
    mb: 3,
    color: "secondaryColor",
    fontSize: 25,
    fontWeight: 600,
    fontFamily: "ubuntuFont"
}

const buttonStyles = {
    _active: { bgColor: "secondaryButtonColor" },
    bgColor: "secondaryButtonColor",
    px: { base: 3, md: 10 },
    color: "secondaryTextColor",
    borderRadius: "15px",
    _hover: { bgColor: "secondaryButtonColor", color: "secondaryTextColor" },
    fontFamily: "interFont"
}

export { headerStyles, buttonStyles }
import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Box
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { buttonStyles, modalStyles, modalFooterButtonStyles } from "./styles"

export default function FilterTableColumnsModal({ allColumns, getToggleHideAllColumnsProps }) {
    const [t] = useTranslation('FilterTableColumnsModal');
    const { isOpen, onOpen, onClose } = useDisclosure();



    return (
        <Box mr={3}>
            <Button {...buttonStyles} onClick={onOpen}>
                {t("toggle-columns")}
            </Button>
            <Modal {...modalStyles} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("display-columns")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <input
                                type="checkbox"
                                {...getToggleHideAllColumnsProps()}
                                id="toggleAllColumns"
                            />
                            <label htmlFor="toggleAllColumns">{t("toggle-all")}</label>
                        </Box>
                        <Box>
                            {allColumns.map((column, i) => (
                                <Box key={i}>
                                    <label>
                                        <input
                                            checked={column.isVisible}
                                            onClick={() => { column.isVisible = !column.isVisible }}
                                            type="checkbox"
                                            {...column.getToggleHiddenProps()}
                                            id={`columnCheckbox-${i}`}
                                        />
                                        {column.Header || column.id}
                                    </label>
                                </Box>
                            ))}
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button {...modalFooterButtonStyles} onClick={onClose}>
                            {t("close")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}

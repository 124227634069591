import React, { Fragment } from 'react'
import {
    Box,
    Link,
    Flex,
    Text,
    Image,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    DrawerCloseButton
} from '@chakra-ui/react'
import { Link as RouterLink } from "react-router-dom"
import { useTranslation } from 'react-i18next';

export default function Sidebar({ isOpen, onClose }) {
    return (
        <>
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
                h="full"
                bgColor="secondaryColor"
                w="full"
                position="fixed" 
            >
                <DrawerOverlay onClick={onClose}/>
                <DrawerContent>
                    <DrawerCloseButton/>
                    <SidebarContent onClose={onClose} display="block" bg="secondaryColor" w="100%" h="full" />
                </DrawerContent>
            </Drawer>
        </>
    )
}

const SidebarContent = ({ onClose ,...rest }) => {
    const [t] = useTranslation('Sidebar');

    const LinkItems = [
        { name: t("my-account"), icon: "my_account.png", link: "/", fatherLink: false },
        { name: "My decisions", icon: "my_decisions.png", link: "/decisions", fatherLink: false},
    ]

    return (
        <Box
            w="30%"
            pos="fixed"
            h="full"
            pt="20px"
            {...rest}>
            {LinkItems.map((link, i) => {
                return (
                    <Fragment key={i}>
                        <NavItem icon={link.icon}>
                            <Link onClick={onClose} onClose={onClose} as={RouterLink} to={link.link} fontSize={18} fontWeight={400} textDecoration="none" _hover={{ textDecoration: "none", color: "secondaryTextColor" }} color="secondaryTextColor">{link.name}</Link>
                        </NavItem>
                    </Fragment>
                )
            })}
        </Box>
    )
}

const NavItem = ({ icon, children, ...rest }) => {
    return (
        <Box
            as="a"
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                borderRadius="lg"
                px="6"
                pt="6"
                pb="3"
                role="group"
                {...rest}>
                {icon && (
                    <Image
                        mr="4"
                        fontSize="16"
                        src={icon}
                    />
                )}
                {children}
            </Flex>
        </Box>
    );
}


import {
    Flex,
    Center,
    Button,
    Stack,
    Box,
    Link
} from "@chakra-ui/react";
import userApi from 'api/auth';
import connectWallet from 'features/web3/utils/connectMetamask';
import personalSign from 'features/web3/utils/personalSign';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { mainFlexStyles, mainStackStyles, mainBoxStyles, secondaryStackStyles, boxWithTextStyles, linkStyles, signinButtonStyles, signinWithMetamaskButtonStyles } from './styles'

const LoginWithMetamaskInstalled = ({ setLoginWithMetamask }) => {
    const [t] = useTranslation('LoginWithMetamaskInstalled');
    const { login } = useCurrentUserContext();
    const navigate = useNavigate()

    const connectWithMetamask = async (nonce, publicAddress) => {
        const message = `Ethelo Login,${Date.now()},${nonce}`;
        const { signature, errorMessage } = await personalSign({ message, publicAddress });
        if (signature) {
            const user = {
                public_address: publicAddress,
                message,
                signature,
            };
            userApi['loginWithMetamask']({ user })
                .then(() => {
                    login();
                    navigate('/', { replace: true });
                })
                .catch(error => {

                });
        } else {
        }
    };

    const loginInAppWithWeb3 = async () => {
        const { publicAddress, errorMessage } = await connectWallet();
        if (publicAddress) {
            userApi.nonce({ user: { public_address: publicAddress } })
                .then(response => {
                    connectWithMetamask(response.nonce, publicAddress);
                })
                .catch(error => {
                    /*setState({
                        ...state,
                        loadingMetamask: false,
                        errorMessage: error,
                    });*/
                });
        } else {
            /*setState({
                ...state,
                loadingMetamask: false,
                errorMessage,
            });*/
        }
    };

    return (
        <Flex {...mainFlexStyles}>
            <Stack {...mainStackStyles}>
                <Box {...mainBoxStyles}>
                    <Stack {...secondaryStackStyles}
                    >
                        <Center mb={-1}>
                            <Box {...boxWithTextStyles} fontWeight={700}>{t("sign-in")}</Box>
                        </Center>
                        <Center>
                            <Box {...boxWithTextStyles}>{t("not-registered-yet")} <Link as={RouterLink} {...linkStyles} to="/signup" >{t("sign-up")}</Link></Box>
                        </Center>
                        <Box w="100%">
                            <Center px={12}>
                                <Button {...signinButtonStyles}
                                    onClick={() => setLoginWithMetamask(false)}
                                >
                                    {t("sign-in-with-password")}
                                </Button>
                            </Center>
                            <Center>
                                <Box my={-4} fontWeight={600}>{t("or")}</Box>
                            </Center>
                            <Center px={12}>
                                <Button
                                    {...signinWithMetamaskButtonStyles}
                                    onClick={() => loginInAppWithWeb3()}
                                >
                                    {t("sign-in-with-metamask")}
                                </Button>
                            </Center>
                        </Box>
                    </Stack>
                </Box>
            </Stack >
        </Flex >
    );
};

export default LoginWithMetamaskInstalled;

export const getErrorMessage = (errorCode) => {
    const errors = {
        auth: {
            invalid_email_or_password: 'Invalid email or password',
            login_not_found: 'Account not found',
            reset_failed: 'Unable to reset your password. Please resend the request email.',
            unexpected_error: 'Service temporarily unavailable, please try again.'
        },
        authentication: {
            web3: {
                invalid_address: 'Public Address is not valid',
                invalid_nonce: 'Nonce is not found',
                invalid_signature: 'Signature is not valid',
                invite_not_found: 'Invite does not exist',
                invite_used: 'This invite has already been used. Please sign in.',
                no_metamask: 'You must install the Metamask plugin to use our web3 features.',
                not_found: 'We could not find an account using this Public Address.',
                rejected: 'Metamask connection rejected',
                taken: 'There is already an account using this Public Address.'
            }
        },
        code: {
            invite_not_found: 'Invite does not exist',
            invite_used: 'This invite has already been used. Please sign in.'
        },
        invite: {
            invite_not_found: 'Invite does not exist',
            invite_used: 'This invite has already been used. Please sign in.'
        },
        messages: {
            accepted: 'must be accepted',
            confirmation: {
                match: 'must match password',
                presence: 'cannot be blank'
            },
            email: {
                invalid: 'must be a valid email',
                presence: 'cannot be blank'
            },
            empty: "can't be empty",
            exclusion: 'is reserved',
            file: {
                file_type: 'Must be an image',
                image_format: 'Must be an image',
                size: 'File is too big'
            },
            inclusion: 'is not included in the list',
            invalid: 'is invalid',
            no_html: 'must be plain text',
            not_a_number: 'is not a number',
            not_an_integer: 'must be an integer',
            password: {
                format: 'must contain at least one uppercase letter, one lowercase letter, a number and symbol',
                length_high: 'cannot be more than 128 characters',
                length_low: 'must be at least 6 characters',
                presence: 'cannot be blank'
            },
            taken: 'has already been taken',
            terms: {
                accepted: 'must be accepted'
            },
            too_long: {
                one: 'is too long (maximum is 1 character)',
                other: 'is too long (maximum is %{count} characters)'
            },
            too_short: {
                one: 'is too short (minimum is 1 character)',
                other: 'is too short (minimum is %{count} characters)'
            },
            username: {
                length: 'must be between 3 and 30 characters',
                presence: 'cannot be blank'
            },
            wrong_length: {
                one: 'is the wrong length (should be 1 character)',
                other: 'is the wrong length (should be %{count} characters)'
            }
        }
    };

    const partsOfCode = errorCode.split('.').slice(1);
    let message = errors;

    try {
        for (const part of partsOfCode) {
            message = message[part];
        }
        return message;
    } catch (error) {
        return 'Error';
    }
}

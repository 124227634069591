const inputStyles = {
    h: 7,
    borderRadius: 0,
    borderColor: "secondaryBorderColor",
    _hover: { borderColor: "secondaryBorderColor" },
    _focus: { borderColor: "secondaryBorderColor", boxShadow: "none" },
};

const boxStyles = {
    w: 150,
    textAlign: "right",
    pb: 5,
};

const textStyles = {
    mb: 2,
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "interFont",
};

export { inputStyles, boxStyles, textStyles }
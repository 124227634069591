import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    Button,
    useDisclosure,
    Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import HubspotForm from 'react-hubspot-form'

export default function HubspotModal() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [t] = useTranslation('HubspotModal');

    return (
        <Box px={0}>
            <Button onClick={onOpen} _active={{ bgColor: "mainColor", borderColor: "buttonColor", border: "2px" }} _focus={{ bgColor: "mainColor", borderColor: "buttonColor", border: "2px", outline: "none" }} py={5} borderColor="buttonColor" borderWidth="3px" bgColor="mainColor" px={{ base: 3, lg: 6 }} color="buttonColor" borderRadius="5px" _hover={{ bgColor: "mainColor", color: "buttonColor" }} fontSize={16} fontWeight={600} fontFamily="interFont">
                {t("book-a-consultation")}
            </Button>
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent p={4}>
                    <HubspotForm
                        portalId='6224498'
                        formId='a14328b1-0fc3-40ad-b6dc-f0d4c59facb9'
                        onSubmit={() => console.log('Form submitted!')}
                        onReady={(form) => console.log('Form ready!')}
                        onError={(error) => console.error('Error loading form:', error)}
                        loading={<div>{t("loading")}...</div>}
                    />
                </ModalContent>
            </Modal>
        </Box>
    );
}
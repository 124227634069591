const mainFlexStyles = {
    flexDirection: "column",
    width: "100wh",
    height: "100vh",
    backgroundColor: "mainColor",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Inter"
}

const mainStackStyles = {
    flexDir: "column",
    mb: "2",
    justifyContent: "center",
    alignItems: "center",
    bgColor: "formBackground"
}

const mainBoxStyles = {
    minW: { md: "468px" }
}

const secondaryStackStyles = {
    pt: 8,
    pb: 8,
    backgroundColor: "formBackground",
    boxShadow: "md"
}

const boxWithTextStyles = {
    color: "mainTextColor"
}

const linkStyles = {
    _hover: { color: "secondaryColor", textDecoration: "none" },
    fontWeight: 400,
    color: "baseLinkColor"
}

const signinButtonStyles = {
    _hover: { bgColor: "secondaryButtonColor" },
    my: 6,
    variant: "solid",
    color: "secondaryTextColor",
    bgColor: "secondaryButtonColor",
    _active: { bgColor: "secondaryButtonColor" },
    width: "full",
    h: 12,
    borderRadius: 8,
    fontSize: 17,
    fontWeight: 300,
    letterSpacing: 0.5
}

const signinWithMetamaskButtonStyles = {
    my: 6,
    _hover: { bgColor: "secondaryButtonColor" },
    _active: { bgColor: "secondaryButtonColor" },
    variant: "solid",
    color: "secondaryTextColor",
    bgColor: "secondaryButtonColor",
    width: "full",
    h: 12,
    borderRadius: 8,
    fontSize: 17,
    fontWeight: 300,
    letterSpacing: 0.5
}

export { mainFlexStyles, mainStackStyles, mainBoxStyles, secondaryStackStyles, boxWithTextStyles, linkStyles, signinButtonStyles, signinWithMetamaskButtonStyles }
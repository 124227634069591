import { useState } from "react";
import {
    Flex,
    Checkbox,
    Input,
    Button,
    InputGroup,
    Stack,
    Link,
    Box,
    Image,
    FormControl,
    Center,
    Text
} from "@chakra-ui/react";
import userApi from 'api/auth';
import isMetaMaskInstalled from 'features/web3/utils/isMetaMaskInstalled';
import connectWallet from 'features/web3/utils/connectMetamask';
import personalSign from 'features/web3/utils/personalSign';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';

const LoginWithoutMetamaskInstalled = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [rememberUser, setRememberUser] = useState(false)
    const navigate = useNavigate();
    const { login } = useCurrentUserContext();
    const hasMetamaskExtension = isMetaMaskInstalled();
    const [t] = useTranslation('LoginWithoutMetamaskInstalled');

    const loginToApp = (email, password) => {
        if (!email || !password) return
        const data = { email, password, remember_me: rememberUser }

        userApi
            .login({ user: data })
            .then(() => {
                login();
                navigate('/', { replace: true });
            })
            .catch(errorMessage => {

            });
    };

    const connectWithMetamask = async (nonce, publicAddress) => {
        const message = `Ethelo Login,${Date.now()},${nonce}`;
        const { signature, errorMessage } = await personalSign({ message, publicAddress });
        if (signature) {
            const user = {
                public_address: publicAddress,
                message,
                signature,
            };
            userApi['loginWithMetamask']({ user })
                .then(() => {
                    login();
                    navigate('/', { replace: true });
                })
                .catch(error => {
                    /*setState({
                        ...state,
                        loadingMetamask: false,
                        errorMessage: error,
                    });*/
                });
        } else {
            /*setState({
                ...state,
                errorMessage,
                loadingMetamask: false,
            });*/
        }
    };

    const loginInAppWithWeb3 = async () => {
        const { publicAddress, errorMessage } = await connectWallet();
        if (publicAddress) {
            userApi.nonce({ user: { public_address: publicAddress } })
                .then(response => {
                    connectWithMetamask(response.nonce, publicAddress);
                })
                .catch(error => {
                    /*setState({
                        ...state,
                        loadingMetamask: false,
                        errorMessage: error,
                    });*/
                });
        } else {
            /*setState({
                ...state,
                loadingMetamask: false,
                errorMessage,
            });*/
        }
    };

    return (
        <Flex
            flexDirection="column"
            width="100wh"
            height="100vh"
            backgroundColor="mainColor"
            justifyContent="center"
            alignItems="center"
            fontFamily="Inter"
        >
            <Stack
                flexDir="column"
                mb="2"
                justifyContent="center"
                alignItems="center"
            >
                <Box minW={{ base: "90%", md: "468px" }}>
                    <Stack
                        spacing={4}
                        px={12}
                        pt={8}
                        pb={10}
                        backgroundColor="formBackground"
                        boxShadow="md"
                    >
                        <Center mb={-3}>
                            <Box color="mainTextColor" fontWeight={700}>{t("sign-in")}</Box>
                        </Center>
                        <Center>
                            <Box color="mainTextColor">{t("not-registered-yet")} <Link as={RouterLink} _hover={{ color: "secondaryColor", textDecoration: "none" }} to="/signup" color="baseLinkColor">{t("sign-up")}</Link></Box>
                        </Center>
                        <FormControl mb={3}>
                            <Text mb={1} color="mainTextColor" fontSize={16} fontWeight={400}>
                                {t("email-address")}
                            </Text>
                            <Center>
                                <InputGroup>
                                    <Input data-testid={'login-input-email'} type="email" value={email} onChange={e => setEmail(e.target.value)} h={8} borderColor="secondaryBorderColor" borderRadius={0} _hover={{ borderColor: "secondaryBorderColor" }} _focus={{ borderColor: "secondaryBorderColor", boxShadow: "none" }} />
                                </InputGroup>
                            </Center>
                        </FormControl>
                        <FormControl>
                            <Flex justify="space-between" mb={3}>
                                <Text color="mainTextColor" fontSize={16} fontWeight={400}>
                                    {t("password")}
                                </Text>
                                <Text>
                                    <Link as={RouterLink} _hover={{ color: "secondaryColor", textDecoration: "none" }} to="/recover-password" color="baseLinkColor">{t("forgot-your-password")}</Link>
                                </Text>
                            </Flex>
                            <Center>
                                <InputGroup>
                                    <Input
                                        h={8} borderColor="secondaryBorderColor" type="password" borderRadius={0} _hover={{ borderColor: "secondaryBorderColor" }} _focus={{ borderColor: "secondaryBorderColor", boxShadow: "none" }}
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                </InputGroup>
                            </Center>
                            <Flex direction="row">
                                <Checkbox mt={8} size="lg" fontSize={10} isChecked={rememberUser} onChange={e => setRememberUser(e.target.checked)}>
                                    {t("remember-me")}
                                </Checkbox>
                            </Flex>
                        </FormControl>
                        <Box>
                            <Center>
                                <Button
                                    _hover={{ bgColor: "secondaryButtonColor" }}
                                    _active={{ bgColor: "secondaryButtonColor" }}
                                    variant="solid"
                                    color="secondaryTextColor"
                                    bgColor="secondaryButtonColor"
                                    width="full"
                                    h={12}
                                    borderRadius={8}
                                    fontSize={17}
                                    fontWeight={400}
                                    letterSpacing={0.5}
                                    onClick={() => loginToApp(email, password)}
                                    mb={2}
                                >
                                    {t("login")}
                                </Button>
                            </Center>
                            {hasMetamaskExtension && <Center>
                                <Button
                                    borderRadius={8}
                                    _active={{ bgColor: "fourthButtonColor" }}
                                    _hover={{ bgColor: "fourthButtonColor" }}
                                    type="submit"
                                    variant="solid"
                                    bgColor={"fourthButtonColor"}
                                    color="mainTextColor"
                                    width="full"
                                    fontSize={17}
                                    fontWeight={400}
                                    letterSpacing={0.5}
                                    onClick={() => loginInAppWithWeb3()}
                                    py={1}
                                >
                                    {t("or-sign-in-with")} <Image w={10} src={"metamask_logo.svg"} ml={2} />
                                </Button>
                            </Center>}
                        </Box>
                    </Stack>
                </Box>
            </Stack >
        </Flex >
    );
};

export default LoginWithoutMetamaskInstalled;

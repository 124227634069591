import { useReducer } from 'react';
import { decisionsInitState, decisionsReducer } from 'features/decisions/reducer';
import apiDecision from 'api/decisions';
import { DECISIONS } from 'features/decisions/actions';
import moment from 'moment-timezone';


export default function useCurrentTable() {
  const [state, dispatch] = useReducer(decisionsReducer, decisionsInitState);

  const fetchDecisionsTemplates = async () => {
    await apiDecision.getDecisionsTemplates().then(({ data }) => {

      const dataOfTemplates = data.map(templateElement => {
        let dataOfTemplateElement = templateElement.attributes
        dataOfTemplateElement.id = templateElement.id
        dataOfTemplateElement.links = templateElement.links
        dataOfTemplateElement.type = templateElement.type
        return dataOfTemplateElement
      })

      dispatch({
        type: DECISIONS.DECISIONS_TEMPLATES,
        payload: {
          dataOfTemplates,
        },
      });
    })
  }

  const fetchDataOfElectionsWhereUserIsInvitedToHost = async () => {

    await apiDecision.getDataOfElectionsWhereUserIsInvitedToHost().then(({ data }) => {
      const dataOfTable = data.map(elementOfTable => {
        let newDataOfTable = elementOfTable.attributes
        newDataOfTable.id = elementOfTable.id
        newDataOfTable.links = elementOfTable.links
        return newDataOfTable
      })

      dispatch({
        type: DECISIONS.TABLE_DECISIONS_INVITED_TO_HOST,
        payload: {
          dataOfTable,
        },
      });
    })
  }

  const fetchDataOfElectionsWhereUserIsInvitedToParticipate = async () => {

    await apiDecision.getDataOfElectionsWhereUserIsInvitedToParticipate().then(({ data }) => {
      const dataOfTable = data.map(elementOfTable => {
        let newDataOfTable = elementOfTable.attributes
        newDataOfTable.id = elementOfTable.id
        newDataOfTable.links = elementOfTable.links
        return newDataOfTable
      })


      dispatch({
        type: DECISIONS.TABLE_DECISIONS_INVITED_TO_PARTICIPATE,
        payload: {
          dataOfTable,
        },
      });
    })
  }

  const fetchDataOfElectionsWhereUserIsParticipating = async () => {
    await apiDecision.getDataOfElectionsWhereUserIsParticipating().then(({ data }) => {
      const dataOfTable = data.map(elementOfTable => {
        let newDataOfTable = elementOfTable.attributes
        newDataOfTable.id = elementOfTable.id
        newDataOfTable.links = elementOfTable.links
        return newDataOfTable
      })

      dispatch({
        type: DECISIONS.TABLE_DECISIONS_PARTICIPATING,
        payload: {
          dataOfTable,
        },
      });
    })
  }

  const fetchDataOfElectionsWhereUserIsHost = async () => {
    await apiDecision.getDataOfElectionsWhereUserIsHost().then(({ data }) => {
      const dataOfTable = data.map(elementOfTable => {
        let newDataOfTable = elementOfTable.attributes
        newDataOfTable.id = elementOfTable.id
        newDataOfTable.links = elementOfTable.links
        return newDataOfTable
      })

      dispatch({
        type: DECISIONS.TABLE_DECISIONS_HOSTING,
        payload: {
          dataOfTable,
        },
      });
    })
  }

  const getDataOfTablesWhereUserIsParticipantOrIsInvitedToParticipate = () => {
    fetchDataOfElectionsWhereUserIsInvitedToHost()
    fetchDataOfElectionsWhereUserIsHost()
  }

  const getDataOfTablesWhereUserIsHostOrIsInvitedToHost = () => {
    fetchDataOfElectionsWhereUserIsInvitedToParticipate()
    fetchDataOfElectionsWhereUserIsParticipating()
  }

  const getDataOfDecisions = () => {
    getDataOfTablesWhereUserIsParticipantOrIsInvitedToParticipate()
    getDataOfTablesWhereUserIsHostOrIsInvitedToHost()
    fetchDecisionsTemplates()
  }

  const convertDate = (dateStr) => {
    const momentOriginal = moment(dateStr, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true);

    if (momentOriginal.isValid()) {
      const formattedDate = momentOriginal.format('MM/DD/YYYY');
      const formattedTime = momentOriginal.format('hh:mm A');
      return [formattedDate, formattedTime];
    }

    const momentWithOffset = moment(dateStr, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true);

    if (momentWithOffset.isValid()) {
      const formattedDate = momentWithOffset.format('MM/DD/YYYY');
      const formattedTime = momentWithOffset.format('hh:mm A');
      return [formattedDate, formattedTime];
    }

    return dateStr;
  }

  const setCreateDecisionData = (decisionData, isTemplate) => {
    decisionData.isTemplate = isTemplate
    dispatch({
      type: DECISIONS.CREATE_DECISION_DATA,
      payload: {
        decisionData,
      },
    });
  }

  const setEditDecisionUrl = url => {
    dispatch({
      type: DECISIONS.EDIT_DECISION_URL,
      payload: {
        url
      }
    })
  }

  return {
    ...state,
    setEditDecisionUrl,
    setCreateDecisionData,
    getDataOfDecisions,
    getDataOfTablesWhereUserIsParticipantOrIsInvitedToParticipate,
    getDataOfTablesWhereUserIsHostOrIsInvitedToHost,
    fetchDataOfElectionsWhereUserIsInvitedToHost,
    fetchDataOfElectionsWhereUserIsInvitedToParticipate,
    fetchDataOfElectionsWhereUserIsParticipating,
    fetchDataOfElectionsWhereUserIsHost,
    convertDate
  };
}

import React, { useEffect } from 'react'
import LoginWithMetamaskInstalled from "components/LoginWithMetamaskInstalled"
import LoginWithoutMetamaskInstalled from "components/LoginWithoutMetamaskInstalled"
import isMetaMaskInstalled from 'features/web3/utils/isMetaMaskInstalled';
import { useState } from 'react';

export default function Login() {
    const hasMetamaskExtension = isMetaMaskInstalled();
    const [loginWithMetamask, setLoginWithMetamask] = useState(hasMetamaskExtension)

    useEffect(() => {
        setLoginWithMetamask(hasMetamaskExtension)
    }, [hasMetamaskExtension])

    return (
        <>
            {loginWithMetamask ? <LoginWithMetamaskInstalled setLoginWithMetamask={setLoginWithMetamask} /> : <LoginWithoutMetamaskInstalled setLoginWithMetamask={setLoginWithMetamask} />}
        </>
    )
}

import { extendTheme } from "@chakra-ui/react";

const fonts = {
    ubuntuFont: "Ubuntu",
    interFont: "Inter"
};

const theme = extendTheme({
    config: {
        initialColorMode: "light",
    },
    colors: {
        mainColor: "#f7f7f7",
        formBackground: "#fcfcfc",
        headerColor: "white",
        secondaryColor: "#083556",
        baseLinkColor: "#1F83BB",
        buttonColor: "#58a45c",
        secondaryButtonColor: "black",
        secondaryInputTextColor: "#484848",
        headerBorder: "#323232",
        errorColor: "#f75858",
        cancelColor: "red",
        inputBorderColor: "#D4D4D4",
        buttonOfTemplateCardColor: "#1A2C8A",
        mainTextColor: "black",
        secondaryTextColor: "white",
        thirdButtonColor: "blue",
        fourthButtonColor: "white",
        borderColor: "gray",
        secondaryBorderColor: "black",
        mainBackgroundColor: "#F9F9F9",
        secondaryBackgroundColor: "white",
        inputDisabledBorderColor: "#D9D9D9"
    },
    fonts,
    components: {
        Button: {
            baseStyle: {
                _active: {
                    boxShadow: "none",
                    border: "none",
                    outline: "none",
                },
                _focus: {
                    boxShadow: "none",
                    border: "none",
                    outline: "none",
                },
            },
        },
        Td: {
            baseStyle: {
                wordWrap: "break-all"
            }
        }
    },
    styles: {
        global: (props) => ({
            body: {
                bg: "mainBackgroundColor",
                color: "black",
            }
        }),
    },
});

const darkTheme = extendTheme({
    config: {
        initialColorMode: "dark",
    },
    colors: {
        headerColor: "#352F44",
        formBackground: "gray",
        secondaryColor: "red",
        baseLinkColor: "green",
        buttonColor: "yellow",
        secondaryInputTextColor: "purple",
        secondaryButtonColor: "magenta",
        headerBorder: "brown",
        errorColor: "#f75858",
        cancelColor: "orange",
        inputBorderColor: "#D4D4D4",
        buttonOfTemplateCardColor: "yellow",
        mainTextColor: "white",
        secondaryTextColor: "black",
        thirdButtonColor: "#f8b500",
        fourthButtonColor: "black",
        borderColor: "#c4c1e0",
        secondaryBorderColor: "gray",
        tableBorderColor: "#EDEDED"

    },
    fonts,
    components: {
        Button: {
            baseStyle: {
                _active: {
                    boxShadow: "none",
                    border: "none",
                    outline: "none",
                },
                _focus: {
                    boxShadow: "none",
                    border: "none",
                    outline: "none",
                },
            },
        },
    },
    styles: {
        global: (props) => ({
            body: {
                bg: "#F9F9F9",
                color: "white"
            },
        }),
    },
});

export { theme, darkTheme };

export const DECISIONS = {
  TABLE_DECISIONS_PARTICIPATING: "SET_TABLE_DECISIONS_PARTICIPATING",
  TABLE_DECISIONS_INVITED_TO_PARTICIPATE: "SET_TABLE_DECISIONS_INVITED_TO_PARTICIPATE",
  TABLE_DECISIONS_HOSTING: "SET_TABLE_DECISIONS_HOSTING",
  TABLE_DECISIONS_INVITED_TO_HOST: "SET_TABLE_DECISIONS_INVITED_TO_HOST",
  DECISIONS_TEMPLATES: "SET_DECISIONS_TEMPLATES",
  CREATE_DECISION_DATA: "SET_CREATE_DECISION_DATA",
  EDIT_DECISION_URL: "SET_EDIT_DECISION_URL"
};


import { useState } from "react";
import {
    Flex,
    Input,
    Button,
    InputGroup,
    Stack,
    Text,
    Box,
    FormControl,
    Center,
    Link,
    Checkbox
} from "@chakra-ui/react";
import connectWallet from '../../features/web3/utils/connectMetamask';
import personalSign from '../../features/web3/utils/personalSign';
import authApi from '../../api/auth';
import isMetaMaskInstalled from '../../features/web3/utils/isMetaMaskInstalled';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from "react-router-dom"
import {
    flexColumnStyles, stackCenteredStyles, flexCenteredStyles, stackFormStyles, linkStyles, inputTextStyles, inputStyles,
    flexboxOfCheckboxStyles, flexboxWithInformationOfPasswordRecoverStyles, textOfCheckboxStyles, buttonStyles
} from "./styles"


const Signup = () => {
    const hasMetamaskExtension = isMetaMaskInstalled()
    const [receiveNotifications, setReceiveNotifications] = useState(false)
    const [agreeWithTerms, setAgreeWithTerms] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const navigate = useNavigate();
    const [t] = useTranslation('Signup');

    const registerInApp = async (name, email) => {
        if (!name || !email) return
        const data = { name, email, terms: true, ethelo_notifications: true }
        authApi
            .signup({ user: data })
            .then(() => {
                navigate('/', { replace: true });
            })
            .catch(errorMessage => {

            });
    };

    const connectWithMetamask = async (nonce, publicAddress, data) => {
        if (!agreeWithTerms) return
        const message = `Ethelo Sign Up,${Date.now()},${nonce}`;
        const { signature } = await personalSign({ message, publicAddress });
        if (signature) {
            const user = {
                ...data,
                terms: agreeWithTerms,
                ethelo_notifications: receiveNotifications,
                public_address: publicAddress,
                message,
                signature,
            };
            authApi["join"]({ user })
                .then(() => {
                    navigate('/', { replace: true });
                })
                .catch(error => {

                });
        } else {
        }
    };

    const registerInAppWithWeb3 = async (name, email) => {

        const data = { name, email }
        const { publicAddress } = await connectWallet();
        if (publicAddress) {
            authApi.nonce({ user: { public_address: publicAddress } })
                .then(response => {
                    connectWithMetamask(response.nonce, publicAddress, data);
                })
                .catch(error => {
                });
        } else {
        }
    };

    return (
        <Flex {...flexColumnStyles}>
            <Stack {...stackCenteredStyles} pt={hasMetamaskExtension ? "100px" : "50px"}>
                <Flex {...flexCenteredStyles}>
                    <Stack {...stackFormStyles}>
                        <Center mb={-3}>
                            <Box color="mainTextColor" fontWeight={700}>{t("sign-up")}</Box>
                        </Center>
                        <Center>
                            <Box color="mainTextColor">{t("already-have-account")} <Link as={RouterLink} to="/" {...linkStyles}>{t("sign-in")}</Link></Box>
                        </Center>
                        <FormControl>
                            <Text {...inputTextStyles}>{t("name")}</Text>
                            <Center>
                                <InputGroup>
                                    <Input type="text" value={name} onChange={e => setName(e.target.value)}
                                        {...inputStyles}
                                    />
                                </InputGroup>
                            </Center>
                        </FormControl>
                        <FormControl>
                            <Text {...inputTextStyles}>{t("email-address")}</Text>
                            <Center>
                                <InputGroup>
                                    <Input type="email" value={email} onChange={e => setEmail(e.target.value)}
                                        {...inputStyles}
                                    />
                                </InputGroup>
                            </Center>
                        </FormControl>
                        <Flex {...flexboxOfCheckboxStyles} mb={-8}>
                            <Checkbox size="lg" isChecked={receiveNotifications} onChange={e => setReceiveNotifications(e.target.checked)} />
                            <Text {...textOfCheckboxStyles}>{t("update-me")}</Text>
                        </Flex>
                        <Flex {...flexboxOfCheckboxStyles} mb={-8}>
                            <Checkbox size="lg" isChecked={agreeWithTerms} onChange={e => setAgreeWithTerms(e.target.checked)} />
                            <Text {...textOfCheckboxStyles}>{t("sign-up-and-agree-ethelo")} <Link as={RouterLink} to="/" {...linkStyles}>{t("terms-and-conditions")}</Link></Text>
                        </Flex>
                        <Flex {...flexboxWithInformationOfPasswordRecoverStyles}>
                            <Text fontSize={14} fontWeight={400}>
                                {t("your-password-will-be-emailed-to-you")}
                            </Text>
                        </Flex>
                        <Center>
                            <Button onClick={() => registerInApp(name, email)} {...buttonStyles}>
                                {t("sign-up-with-email")}
                            </Button>
                        </Center>
                        {hasMetamaskExtension && <Box>
                            <Center fontWeight={600} mb={4}>
                                {t("or")}
                            </Center>
                            <Center>
                                <Button onClick={() => registerInAppWithWeb3(name, email)} {...buttonStyles}>
                                    {t("sign-up-with-email-and-metamask")}
                                </Button>
                            </Center>
                        </Box>}
                    </Stack>
                </Flex>
            </Stack>
        </Flex>
    );
};

export default Signup;

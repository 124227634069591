import React from 'react';
import { Outlet } from 'react-router-dom';

import Sidebar from "../Sidebar";
import { Box } from '@chakra-ui/react';

const SidebarLayout = ({ isOpen, onClose }) => {

    return (
        <Box>
            <Sidebar isOpen={isOpen} onClose={onClose} />
            <Outlet />
        </Box>
    );
}

export default SidebarLayout; 
import Decisions_en from '../en/pages/Decisions.json';
import CreateDecision_en from '../en/pages/CreateDecision.json';
import CreateDecisionFormPage_en from '../en/pages/CreateDecisionFormPage.json';
import Signup_en from '../en/pages/Signup.json';
import DecisionCreated_en from "../en/pages/DecisionCreated.json"

import CreateDecisionForm_en from '../en/components/CreateDecisionForm';
import TemplatesList_en from '../en/components/TemplatesList';
import TemplateCard_en from '../en/components/TemplateCard';
import DecisionCopyTable_en from '../en/components/DecisionCopyTable';
import ChangePassword_en from '../en/components/ChangePassword.json';
import DeleteAccountModal_en from '../en/components/DeleteAccountModal.json';
import FilterTableColumnsModal_en from '../en/components/FilterTableColumnsModal.json';
import GlobalFilter_en from '../en/components/GlobalFilter.json';
import Header_en from '../en/components/Header.json';
import LoginWithMetamaskInstalled_en from '../en/components/LoginWithMetamaskInstalled.json';
import LoginWithoutMetamaskInstalled_en from '../en/components/LoginWithoutMetamaskInstalled.json';
import RecoverPasswordDone_en from '../en/components/RecoverPasswordDone.json';
import RecoverPasswordForm_en from '../en/components/RecoverPasswordForm.json';
import Sidebar_en from '../en/components/Sidebar.json';
import TableOfActionsHosting_en from '../en/components/TableOfActionsHosting.json';
import TableOfActionsInvitedToHost_en from '../en/components/TableOfActionsInvitedToHost.json';
import TableOfActionsInvitedToParticipate_en from '../en/components/TableOfActionsInvitedToParticipate.json';
import TableOfActionsParticipating_en from '../en/components/TableOfActionsParticipating.json';
import CreateDecisionModal_en from "../en/components/CreateDecisionModal.json";
import EditProfilePhoto_en from "../en/components/EditProfilePhoto.json";
import HubspotModal_en from '../en/components/HubspotModal.json';

const i18nextConfig = {
    interpolation: { escapeValue: false },
    lng: 'english',
    resources: {
        english: {
            TemplatesList: TemplatesList_en,
            Decisions: Decisions_en,
            CreateDecision: CreateDecision_en,
            CreateDecisionFormPage: CreateDecisionFormPage_en,
            CreateDecisionForm: CreateDecisionForm_en,
            Signup: Signup_en,
            DecisionCopyTable: DecisionCopyTable_en,
            ChangePassword: ChangePassword_en,
            DeleteAccountModal: DeleteAccountModal_en,
            FilterTableColumnsModal: FilterTableColumnsModal_en,
            GlobalFilter: GlobalFilter_en,
            Header: Header_en,
            LoginWithMetamaskInstalled: LoginWithMetamaskInstalled_en,
            LoginWithoutMetamaskInstalled: LoginWithoutMetamaskInstalled_en,
            RecoverPasswordDone: RecoverPasswordDone_en,
            RecoverPasswordForm: RecoverPasswordForm_en,
            Sidebar: Sidebar_en,
            TableOfActionsHosting: TableOfActionsHosting_en,
            TableOfActionsInvitedToHost: TableOfActionsInvitedToHost_en,
            TableOfActionsInvitedToParticipate: TableOfActionsInvitedToParticipate_en,
            TableOfActionsParticipating: TableOfActionsParticipating_en,
            TemplateCard: TemplateCard_en,
            DecisionCreated: DecisionCreated_en,
            CreateDecisionModal: CreateDecisionModal_en,
            EditProfilePhoto: EditProfilePhoto_en,
            HubspotModal: HubspotModal_en
        }
    }
};

export default i18nextConfig;


import React from 'react'
import { Modal, ModalOverlay, Link, Flex, ModalBody, Button, ModalContent, Text, Image } from "@chakra-ui/react"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import LeftArrow from 'images/leftArrow'
import { useTranslation } from 'react-i18next';


export default function CreateDecisionModal({ editDecisionLink, openModal }) {
    const navigate = useNavigate()
    const [t] = useTranslation('CreateDecisionModal');

    return (
        <Modal isOpen={openModal}>
            <ModalOverlay onClick={() => navigate('/create-decisions', { replace: true })} />
            <ModalContent px={8} pt={4} pb={14} textAlign="center" my="auto" size="sm" maxW="550px">
                <Link as={RouterLink} to="/create-decisions" textAlign="left" textDecoration="underline" color="secondaryColor" px={0} fontWeight={700} _hover={{ color: "secondaryColor" }}>
                    <Flex direction="row" align="center" w={20}>
                        <LeftArrow active={true} />
                        <Text pt={3} pl={2}>{t("go-back")}</Text>
                    </Flex>
                </Link>

                <ModalBody mt={6} px={24}>
                    <Image src="ethelo_logo.png" mx="auto" w={20} mb={6} />
                    <Text textAlign="center" color="secondaryColor" fontSize={18} fontWeight={700} mb={0}>
                        {t("your-decision-is-ready-for-editing")}
                    </Text>
                    <Text textAlign="center" fontSize={13} mb={8}>
                        {t("your-decision-is-ready")}
                    </Text>
                    <Link _hover={{ textDecoration: "none" }} href={editDecisionLink} target='_blank'>
                        <Button borderRadius="8px" backgroundColor='buttonColor' color="secondaryTextColor" _hover={{ backgroundColor: "buttonColor", color: "secondaryTextColor" }} w="full">
                            {t("edit-decision")}
                        </Button>
                    </Link>
                </ModalBody>
            </ModalContent>
        </Modal >
    )
}

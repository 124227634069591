import { textAlign } from "@mui/system"

const mainBoxStyles = {
    pt: "60px"
}

const headerTextStyles = {
    color: "secondaryColor",
    fontSize: 20,
    fontWeight: 700,
    fontFamily: "ubuntuFont",
    textAlign: "center"
}

const secondaryBoxStyles = {
    w: "30%",
    pt: 10
}

const inputTextStyles = {
    color: "secondaryColor",
    fontSize: 15,
    fontWeight: 600,
    mb: "10px"
}

const inputStyles = {
    bgColor: "mainColor",
    color: "secondaryInputTextColor",
    _focus: {
        outline: "none",
        boxShadow: "none",
        borderColor: "secondaryInputTextColor",
        border: "1px"
    }
}

const buttonStyles = {
    _active: { bgColor: "buttonColor" },
    bgColor: "buttonColor",
    color: "secondaryTextColor",
    px: "30px",
    fontSize: 14,
    fontWeight: 600,
    _hover: { bgColor: "buttonColor" }
}

export { mainBoxStyles, headerTextStyles, secondaryBoxStyles, inputTextStyles, inputStyles, buttonStyles }
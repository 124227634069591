import React, { useState } from "react"
import { Link as LinkRouter } from "react-router-dom"
import { useCurrentUserContext } from 'features/user/contexts/user-provider';
import { Sun, Moon } from 'react-feather';
import { Menu, MenuButton, MenuList, MenuItem, Flex, Text, Box, Image, Link, Avatar, IconButton, Icon } from '@chakra-ui/react';
import { HamburgerIcon } from "@chakra-ui/icons";
import { useTranslation } from 'react-i18next';
import { flexStyles, textStyles, linkStyles, menuButtonStyles, menuListStyles, boxStyles, iconButtonStyles, logoImageStyles, avatarStyles, flexWithNameAndAvatarStyles, boxWithUserName, menuItemStyles, boxWithDarkModeItems } from "./styles"

export default function HeaderComponent({ onOpen, changeBetweenLightAndDarkMode, lightMode }) {
    const { authorized, logout, user } = useCurrentUserContext();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [t] = useTranslation('Header');

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <Flex {...flexStyles} pl={authorized ? "22px" : "66px"}>
            <Flex alignItems="center">
                {authorized && <IconButton
                    {...iconButtonStyles}
                    onClick={onOpen}
                    icon={<HamburgerIcon />}
                />}
                <Image {...logoImageStyles} />
                <Text {...textStyles}>Ethelo</Text>
            </Flex>
            <Box {...boxStyles}>
                <Flex alignItems="center">
                    {!authorized && <Link as={LinkRouter} to="/login" {...linkStyles}>{t("login")}</Link>}
                    {!authorized && <Link as={LinkRouter} to="/signup" {...linkStyles}>{t("sign-up")}</Link>}
                    {authorized &&
                        <Flex direction="row">
                            <Box {...boxWithDarkModeItems} onClick={changeBetweenLightAndDarkMode}>
                                <Image src={lightMode ? "moon.png" : "sun.png"} w={6} mt={1} />
                            </Box>
                            <Flex {...flexWithNameAndAvatarStyles}>
                                {user && user.avatar && <Avatar {...avatarStyles} src={user.avatar} />}
                                {user && user.name && <Box {...boxWithUserName}>Hi, {user.name}</Box>}
                            </Flex>
                            <Menu>
                                <MenuButton as={Flex} {...menuButtonStyles}>
                                    <Image src={"down_arrow.png"} w={3} mt={1} onClick={() => toggleMenu()} />
                                </MenuButton>
                                <MenuList {...menuListStyles} isOpen={isMenuOpen} >
                                    <Flex direction="column">
                                        <MenuItem {...menuItemStyles}>
                                            <Flex align="center">
                                                <Image src={"most_recent_project.png"} mr={2} />
                                                {t("most-recent-project")}
                                            </Flex>
                                        </MenuItem>
                                        <MenuItem {...menuItemStyles}>
                                            <Flex align="center">
                                                <Image src={"member_home.png"} mr={2} />
                                                {t("member-home")}
                                            </Flex>
                                        </MenuItem>
                                        <MenuItem onClick={() => logout()} {...menuItemStyles}>
                                            <Flex align="center">
                                                {t("logout")}
                                            </Flex>
                                        </MenuItem>
                                    </Flex>
                                </MenuList>
                            </Menu>
                        </Flex>
                    }
                </Flex>
            </Box>
        </Flex>
    );
}



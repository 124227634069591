import React, { useState } from 'react';
import { Box, Button, Text, Card, CardBody, Image, Stack, Flex, Avatar } from '@chakra-ui/react';
import { labelTextStyles, addImageButtonStyles, sendImageButtonStyles, removeImageButtonStyles } from "./styles"
import { useCurrentUserContext } from 'features/user/contexts/user-provider';
import { useTranslation } from 'react-i18next';

const EditProfilePhoto = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const { user } = useCurrentUserContext();
    const [t] = useTranslation('EditProfilePhoto');

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
        setIsImageUploaded(true);
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        setIsImageUploaded(false);
    };

    const handleUpload = () => {
        if (selectedImage) {
            console.log('Selected image:', selectedImage);
        } else {
            console.log('No image.');
        }
    };

    return (
        <Card maxW='sm' mt={20} py={10} px={12}>
            <Text {...labelTextStyles} textAlign="center">
                {t("change-profile-photo")}
            </Text>
            <Flex direction="column" align="center" >
                <Avatar
                    src={user && user.avatar ? user.avatar : null}
                    borderRadius='100%'
                    w={32}
                    h={32}
                    mt={8}
                    mb={16} />
                <Button {...removeImageButtonStyles} isDisabled={!selectedImage} onClick={handleRemoveImage}>
                    {t("remove-image")}
                </Button>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    id="fileInput"
                />
                {!isImageUploaded ? (
                    <label htmlFor="fileInput" style={{ width: "100%" }}>
                        <Button as="span" {...sendImageButtonStyles}>
                            {t("add-image")}
                        </Button>
                    </label>
                ) : (
                    <Button as="span" {...sendImageButtonStyles} onClick={handleUpload}>
                        {t("send-image")}
                    </Button>
                )}
            </Flex>
        </Card>
    );
}

export default EditProfilePhoto;
import React from 'react'

export default function leftArrow({ active }) {
    return (
        <svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-2.18557e-07 4.99999C-2.00906e-07 4.59618 0.157024 4.19237 0.465254 3.88663L4.25705 0.125469C4.4257 -0.041823 4.70485 -0.041823 4.87351 0.125469C5.04216 0.292761 5.04216 0.569658 4.87351 0.73695L1.08171 4.49812C0.802563 4.77501 0.802563 5.22497 1.08171 5.50187L4.87351 9.26305C5.04216 9.43034 5.04216 9.70724 4.87351 9.87453C4.70485 10.0418 4.4257 10.0418 4.25705 9.87453L0.465254 6.11335C0.157024 5.80761 -2.36208e-07 5.4038 -2.18557e-07 4.99999Z" fill="#083556" fill-opacity={active ? "1" : "0.54"} />
        </svg>

    )
}

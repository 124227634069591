import React from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Flex, InputGroup, Input, InputLeftElement, Image } from '@chakra-ui/react'
import TableOfActionsHosting from 'components/TableOfActionsHosting';
import TableOfActionsInvitedToHost from 'components/TableOfActionsInvitedToHost';
import TableOfActionsParticipating from 'components/TableOfActionsParticipating';
import TableOfActionsInvitedToParticipate from 'components/TableOfActionsInvitedToParticipate';
import { useTranslation } from 'react-i18next';
import { mainBoxStyles, tabsStyles, tabListStyles, tabStyles, tabPanelsStyles } from "./styles"

const Decisions = () => {
    const [t] = useTranslation('Decisions');

    return (
        <Box {...mainBoxStyles}>
            <Tabs {...tabsStyles}>
                <Flex justifyContent="space-between" alignItems="flex-end" w="full">
                    <TabList {...tabListStyles}>
                        <Tab {...tabStyles} borderTopLeftRadius="10px">
                            {t("title-decisions-hosting")}
                        </Tab>
                        <Tab {...tabStyles}>
                            {t("title-decisions-invited-to-host")}
                        </Tab>
                        <Tab {...tabStyles}>
                            {t("title-decisions-participating")}
                        </Tab>
                        <Tab {...tabStyles} borderTopRightRadius="10px">
                            {t("title-decisions-invited-to-participate")}
                        </Tab>
                    </TabList>
                    <Box>
                        <InputGroup>
                            <InputLeftElement children={<Image src={'search.png'} ml={5} />} />
                            <Input w="500px" placeholder='Search' bgColor="secondaryBackgroundColor" borderColor="secondaryColor" borderRadius="25px" _hover={{ borderColor: "secondaryColor" }} />
                        </InputGroup>
                    </Box>
                </Flex>
                <TabPanels {...tabPanelsStyles}>
                    <TabPanel p={0}>
                        <TableOfActionsHosting />
                    </TabPanel>
                    <TabPanel p={0}>
                        <TableOfActionsInvitedToHost />
                    </TabPanel>
                    <TabPanel p={0}>
                        <TableOfActionsParticipating />
                    </TabPanel>
                    <TabPanel p={0}>
                        <TableOfActionsInvitedToParticipate />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
}

export default Decisions;

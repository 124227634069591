import Client from 'api/client';

const api = () => {
  return {
    getCurrentUser: () => {
      return Client.get('member/current_user');
    },
    updateCurrentUser: data => {
      return Client.post('member/current_user', data);
    },
  };
};

export default api();

import { useState } from "react";
import {
    Flex,
    Input,
    Button,
    InputGroup,
    Stack,
    Link,
    Box,
    FormControl,
    Center,
    Text
} from "@chakra-ui/react";
import userApi from 'api/auth';
import { Link as RouterLink } from "react-router-dom"
import { useTranslation } from 'react-i18next';


const RecoverPasswordForm = ({ setWasMailSent }) => {
    const [t] = useTranslation('RecoverPasswordForm');
    const [email, setEmail] = useState("")

    const sendRecoverPasswordLink = () => {
        if (!email) return
        const data = { email }

        userApi
            .requestPasswordReset({ user: data })
            .then(() => {
                setWasMailSent(true)
            })
            .catch(errorMessage => {

            });
    };


    return (
        <Flex
            flexDirection="column"
            width="100wh"
            height="100vh"
            backgroundColor="mainColor"
            justifyContent="center"
            alignItems="center"
            fontFamily="Inter"
        >
            <Stack
                flexDir="column"
                mb="2"
                justifyContent="center"
                alignItems="center"
            >
                <Box minW={{ base: "90%", md: "468px" }}>
                    <Stack
                        spacing={4}
                        px={16}
                        pt={8}
                        pb={4}
                        backgroundColor="formBackground"
                        boxShadow="md"
                    >
                        <Center mb={-1}>
                            <Box color="mainTextColor" fontWeight={700}>{t("forgot-your-password")}</Box>
                        </Center>
                        <Center px={12}>
                            <Text color="mainTextColor" textAlign="center" fontSize={16} fontWeight={400}>{t("we-can-mail-reset-instructions")} <br /> {t("password")}</Text>
                        </Center>
                        <FormControl mb={3}>
                            <Text mb={1} color="mainTextColor" fontSize={16} fontWeight={400}>
                                {t("email-address")}
                            </Text>
                            <Center>
                                <InputGroup>
                                    <Input type="email" value={email} onChange={e => setEmail(e.target.value)} h={8} borderColor="secondaryBorderColor" borderRadius={0} _hover={{ borderColor: "secondaryBorderColor" }} _focus={{ borderColor: "secondaryBorderColor", boxShadow: "none" }} />
                                </InputGroup>
                            </Center>
                        </FormControl>
                        <Box>
                            <Center>
                                <Button
                                    _hover={{ bgColor: "secondaryButtonColor" }}
                                    _active={{ bgColor: "secondaryButtonColor" }}
                                    variant="solid"
                                    color="secondaryTextColor"
                                    bgColor="secondaryButtonColor"
                                    width="full"
                                    h={12}
                                    borderRadius={8}
                                    fontSize={17}
                                    fontWeight={400}
                                    letterSpacing={0.5}
                                    onClick={() => sendRecoverPasswordLink()}
                                    mb={2}
                                >
                                    {t("send-reset-email")}
                                </Button>
                            </Center>
                            <Center px={12}>
                                <Text color="mainTextColor" textAlign="center" fontSize={16} fontWeight={400}>
                                    {t("return-to")} <Link as={RouterLink} _hover={{ color: "secondaryColor", textDecoration: "none" }} to="/" color="baseLinkColor">{t("sign-in")}</Link>
                                </Text>
                            </Center>
                        </Box>
                    </Stack>
                </Box>
            </Stack >
        </Flex >
    );
};

export default RecoverPasswordForm;

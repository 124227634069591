const flexColumnStyles = {
    flexDirection: "column",
    width: "100wh",
    minHeight: "100vh",
    backgroundColor: "mainColor",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Inter"
}

const stackCenteredStyles = {
    flexDir: "column",
    mb: "2",
    justifyContent: "center",
    alignItems: "center"
}

const flexCenteredStyles = {
    minW: { base: "90%", md: "468px" },
    align: "center",
    minH: "100vh"
}

const stackFormStyles = {
    spacing: 4,
    px: 12,
    pt: 8,
    pb: 10,
    backgroundColor: "formBackground",
    boxShadow: "md"
}

const linkStyles = {
    _hover: { color: "secondaryColor", textDecoration: "none" },
    color: "baseLinkColor"
}

const inputTextStyles = {
    mb: 1,
    color: "mainTextColor",
    fontSize: 16,
    fontWeight: 400
}

const inputStyles = {
    h: 8,
    borderColor: "secondaryBorderColor",
    borderRadius: 0,
    _hover: { borderColor: "secondaryBorderColor" },
    _focus: { borderColor: "secondaryBorderColor", boxShadow: "none" }
}

const flexboxOfCheckboxStyles = {
    direction: "row",
    h: 8,
    mt: 2,
    align: "center",
}

const flexboxWithInformationOfPasswordRecoverStyles = {
    justify: "right",
    mb: - 4,
    color: "mainTextColor"
}

const textOfCheckboxStyles = {
    pt: 2,
    ml: 3,
    color: "mainTextColor",
    fontSize: 16,
    fontWeight: 400,
    mt: -4
}

const buttonStyles = {
    _hover: { bgColor: "secondaryButtonColor" },
    variant: "solid",
    color: "secondaryTextColor",
    bgColor: "secondaryButtonColor",
    width: "full",
    h: 12,
    borderRadius: 8,
    fontSize: 17,
    fontWeight: 400,
    letterSpacing: 0.5
}

export { flexColumnStyles, stackCenteredStyles, flexCenteredStyles, stackFormStyles, linkStyles, inputTextStyles, inputStyles, flexboxOfCheckboxStyles, flexboxWithInformationOfPasswordRecoverStyles, textOfCheckboxStyles, buttonStyles }
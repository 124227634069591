import { border, fontWeight, width } from "@mui/system";

const labelTextStyles = {
    color: "secondaryColor",
    fontSize: 20,
    fontWeight: 700,
    fontFamily: "ubuntuFont"
};

const addImageButtonStyles = {
    mt: 2,
    _active: { bgColor: "buttonColor" },
    _hover: { bgColor: "buttonColor" },
    bgColor: "buttonColor",
    color: "secondaryTextColor",
    cursor: "pointer"
};

const sendImageButtonStyles = {
    mt: 2,
    _active: { bgColor: "buttonColor" },
    _hover: { bgColor: "buttonColor" },
    bgColor: "buttonColor",
    color: "secondaryTextColor",
    cursor: "pointer",
    width: "full",
    fontWeight: 400
};

const removeImageButtonStyles = {
    mt: 2,
    _active: { bgColor: "errorColor" },
    _hover: { bgColor: "errorColor" },
    _disabled: { bgColor: "mainColor", color: 'secondaryInputTextColor', cursor: "auto", fontWeight: 300 },
    bgColor: "errorColor",
    color: "secondaryTextColor",
    cursor: "pointer",
    w: "full",
    fontWeight: 400
};

export { labelTextStyles, addImageButtonStyles, sendImageButtonStyles, removeImageButtonStyles }
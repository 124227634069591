import React, { useState } from 'react'
import {
    Button,
    Text,
    Image,
    Box,
    FormControl,
    Input,
    InputGroup,
    Card, CardBody, Stack, Heading
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
import { mainBoxStyles, headerTextStyles, secondaryBoxStyles, inputTextStyles, inputStyles, buttonStyles } from "./styles"

export default function ChangePassword() {
    const [currentPassord, setCurrentPassword] = useState("")
    const [newPassword, setNewPassord] = useState("")
    const [newPasswordVerification, setNewPasswordVerification] = useState("")
    const [t] = useTranslation('ChangePassword');


    return (
        <Card mt={10} py={10} px={12}>
            <Text {...headerTextStyles}>{t("change-my-password")}</Text>
            <CardBody>
                <Box mb={3} autoComplete="off">
                    <Text {...inputTextStyles}>{t("current-password")}</Text>
                    <InputGroup autoComplete="off">
                        <Input
                            {...inputStyles}
                            type="text"
                            placeholder="xxxxxxxxxxxxxx"
                            value={currentPassord}
                            onChange={e => setCurrentPassword(e.target.value)}
                        />
                    </InputGroup>
                </Box>
                <FormControl mb={3}>
                    <Text {...inputTextStyles}>{t("new-password")}</Text>
                    <InputGroup>
                        <Input
                            {...inputStyles}
                            type="password"
                            placeholder="xxxxxxxxxxxxxx"
                            value={newPassword}
                            onChange={e => setNewPassord(e.target.value)}
                        />
                    </InputGroup>
                </FormControl>
                <FormControl>
                    <Text {...inputTextStyles}>{t("repeat-password")}</Text>
                    <InputGroup>
                        <Input
                            {...inputStyles}
                            type="password"
                            placeholder="xxxxxxxxxxxxxx"
                            value={newPasswordVerification}
                            onChange={e => setNewPasswordVerification(e.target.value)}
                        />
                    </InputGroup>
                </FormControl>
            </CardBody>
            <Button {...buttonStyles}><Image src="save.png" mr="8px" />{t("save")}</Button>
        </Card>
    );
}

import React, { useState } from 'react'
import RecoverPasswordForm from "components/RecoverPasswordForm"
import RecoverPasswordDone from "components/RecoverPasswordDone"

export default function RecoverPassword() {
    const [wasMailSent, setWasMailSent] = useState(false)
    return (
        <>
            {wasMailSent ? <RecoverPasswordDone /> : <RecoverPasswordForm setWasMailSent={setWasMailSent} />}
        </>
    )
}

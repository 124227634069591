import React from 'react'
import { Box, Image, Text, Button, Flex, Link } from "@chakra-ui/react"
import { useNavigate } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';
import { useCurrentDecisionsContext } from 'features/decisions/contexts/decisions-provider';
import { useTranslation } from 'react-i18next';

export default function TemplateCard({ decisionTemplate }) {
    const { title, image, info, links } = decisionTemplate
    const navigate = useNavigate()
    const { setCreateDecisionData } = useCurrentDecisionsContext()
    const [t] = useTranslation('TemplateCard');

    const goToCreateFormRoute = (data) => {
        setCreateDecisionData(data, true)
        navigate('/create-decision-form', { replace: true });
    }

    return (
        <Box maxW='sm' bgColor="mainColor" px={7} py={8} borderRadius="10px" style={{ boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px rgba(27, 31, 35, 0.15) 0px 0px 0px 1px" }}>
            <Text mb={2} py={0} fontSize={20} fontWeight={600} color="secondaryColor">{title}</Text>
            <Image
                src={image}
                alt='decision template'
                borderRadius='lg'
            />
            <Text fontFamily="ubuntuFont">
                {HTMLReactParser(info)}
            </Text>
            <Flex justify="space-between">
                <Link href={links.visit} target='_blank' _hover={{ textDecoration: "none" }}>
                    <Button _active={{ bgColor: "buttonColor" }} py={5} borderColor="buttonColor" border="2px" bgColor="buttonColor" px={{ base: 3, lg: "54px" }} color="secondaryTextColor" borderRadius="5px" _hover={{ bgColor: "buttonColor", color: "secondaryTextColor" }} fontSize={16} fontWeight={400} fontFamily="interFont">{t("demo")}</Button>
                </Link>
                <Button _active={{ bgColor: "transparent", borderColor: "buttonColor", border: "2px" }} py={5} borderColor="buttonColor" border="2px" bgColor="transparent" px={{ base: 3, lg: "54px" }} color="buttonColor" borderRadius="5px" _hover={{ bgColor: "transparent", color: "buttonColor" }} fontSize={16} fontWeight={400} fontFamily="interFont" onClick={() => goToCreateFormRoute(decisionTemplate)}>{t("create")}</Button>
            </Flex>
        </Box>
    )
}
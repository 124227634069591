import React from 'react'
import { Box } from '@chakra-ui/react'
import CreateDecisionForm from "components/CreateDecisionForm"
import { useTranslation } from 'react-i18next';

export default function CreateDecisionFormPage() {
  const [t] = useTranslation('CreateDecisionFormPage');
  return (
    <Box pt={32} pb={12}>
      <CreateDecisionForm />
    </Box>
  )
}

const buttonStyles = {
    _active: { bgColor: "buttonColor" },
    bgColor: "buttonColor",
    color: "secondaryTextColor",
    borderRadius: "15px",
    _hover: { bgColor: "buttonColor", color: "secondaryTextColor" },
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "interFont"
};



const modalStyles = {
    bgColor: "formBackground"
};

const modalFooterButtonStyles = {
    _active: { bgColor: "cancelColor" },
    bgColor: "cancelColor",
    color: "secondaryTextColor",
    _hover: { bgColor: "cancelColor" }
};

export { buttonStyles, modalStyles, modalFooterButtonStyles }
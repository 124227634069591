import { positions } from "@mui/system";

const inputStyles = {
    bgColor: "mainColor",
    color: "secondaryInputTextColor",
    _focus: {
        outline: "none",
        boxShadow: "none",
        borderColor: "secondaryInputTextColor",
        border: "1px"
    }
}

const chakraStyles = {
    container: (provided) => ({
        ...provided,
        h: 10,
        borderRadius: "0px !important",
        zIndex: 3
    }),

    dropdownIndicator: (provided) => ({
        ...provided,
        background: "transparent",
        border: 0,
        padding: 2,
        borderColor: "green",
        h: 10,
        borderRadius: "0px !important",
        with: "30%",
        zIndex: 3
    }),

    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        position: "relative"
    }),

    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
        zIndex: 3,
        position: "relative"
    }),

    control: (provided) => ({
        ...provided,
        ...inputStyles,
        zIndex: 3,
        position: "relative"
    }),
};

const principalComponentStyles = {
    pl: 4,
    pr: { base: "10px", md: "150px", lg: "280px" },
    pb: 8
}

const headerTextStyles = {
    color: "secondaryColor",
    fontSize: 25,
    fontWeight: 600,
    fontFamily: "ubuntuFont",
    mb: 1
}

const flexColumnStyles = {
    direction: "column",
    w: "100%"
}


const errorTextStyles = {
    color: "errorColor",
    textAlign: "left",
    mb: 0
}

const createDecisionButtonStyles = {
    bgColor: "buttonColor",
    px: 10,
    color: "secondaryTextColor",
    borderRadius: "8px",
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "interFont",
    mt: 2,
    _active: { bgColor: "buttonColor" },
    _hover: { bgColor: "buttonColor", color: "secondaryTextColor" },

}

export { inputStyles, chakraStyles, principalComponentStyles, headerTextStyles, flexColumnStyles, errorTextStyles, createDecisionButtonStyles }
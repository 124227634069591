import React, { useMemo, useCallback, Fragment, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom"
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Flex,
    TableContainer,
    Button,
    Box,
    Text,
    Select,
    Link,
    Image
} from '@chakra-ui/react';
import {
    useGlobalFilter,
    useSortBy,
    useTable,
    useExpanded,
    usePagination,
} from 'react-table';
import {
    tableContainerStyles,
    tHeadStyles,
    tHStyles,
    flexSortStyles,
    sortButtonStyles,
    mainTdStyles,
    tHActions,
    secondaryTdStyles,
    flexContainerStyles,
    linkStyles,
    buttonCopyStyles,
    buttonVisitStyles,
    outerFlexStyles,
    columnFlexStyles,
    showingTextStyles,
    previousPageTextStyles,
    selectStyles,
    nextPageTextStyles,
    tRHeadStyles,
    tRBodyStyles
} from "./styles"
import GlobalFilter from 'components/GlobalFilter';
import { useCurrentDecisionsContext } from 'features/decisions/contexts/decisions-provider';
import HTMLReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import LeftArrow from 'images/leftArrow';
import RightArrow from 'images/rightArrow';

export default function DecisionCopyTable({ tableInstance, columns, setTableState }) {
    const [t] = useTranslation('DecisionCopyTable');

    const { tableDecisionsHostingData, setCreateDecisionData } = useCurrentDecisionsContext()

    const { dataOfTable } = tableDecisionsHostingData



    const navigate = useNavigate()

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        state,
        rows,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        nextPage,
        gotoPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = tableInstance;


    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <Td colSpan={visibleColumns.length + 1} style={{ width: '100%', whiteSpace: 'pre-wrap' }} pr={2}>
                <Text fontSize={15} fontWeight={400}>
                    {HTMLReactParser(dataOfTable[row.id].info)}
                </Text>
            </Td>
        ),
        [dataOfTable]
    );

    const checkIfExpanderIsVisible = (columnsList) => {
        const columnExpander = columnsList.find(
            (currentColumn) => currentColumn.column.Header === 'Decision'
        );
        if (columnExpander) {
            return columnExpander.column.isVisible;
        }
        return false;
    };

    const saveTableStateToLocal = (tableState) => {
        localStorage.setItem('decisionsCopyTable', JSON.stringify(tableState));
    };

    const getLowerNumber = (numberA, numberB) => {
        if (numberA < numberB) {
            return numberA
        } else {
            return numberB
        }
    }

    const goToCreateFormRoute = (row) => {
        const { original } = row
        setCreateDecisionData(original, false)
        navigate('/create-decision-form', { replace: true });
    }

    const getPaginationButtons = (pagesNumber) => {
        const paginationButtons = () => {
            const buttonList = [];
            for (let i = 0; i < pagesNumber; i++) {
                buttonList.push(<Button borderRadius="100%" _active={{ border: "1px", borderColor: "secondaryColor" }} _focus={{ outline: "none", borderColor: "secondaryColor" }} fontFamily="ubuntu" fontWeight={400} mr={1} size="xs" border="1px" borderColor="secondaryColor" color="secondaryColor" bgColor={pageIndex === i ? "rgba(8, 53, 86, 0.25)" : "transparent"} _hover={{ bgColor: "rgba(8, 53, 86, 0.25)" }} onClick={() => gotoPage(i)} key={i}>{i + 1}</Button>);
            }
            return buttonList;
        };

        return <>{paginationButtons()}</>;
    }

    useEffect(() => {
        saveTableStateToLocal(state);
        setTableState(state)
    }, [state]);

    return (
        <Box>
            <Table columns={columns} data={dataOfTable} pageCount={pageCount} renderRowSubComponent={renderRowSubComponent} />
            <TableContainer {...tableContainerStyles} borderRadius="7px">
                <div className='scroll-container'>
                    <Table {...getTableProps()}>
                        <Thead {...tHeadStyles} borderBottom="none">
                            {headerGroups.map((headerGroup, i) => (
                                <Tr {...headerGroup.getHeaderGroupProps()} key={i} {...tRHeadStyles}>
                                    {headerGroup.headers.map((column, i) => (
                                        <Th
                                            key={i}
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            pr={{ base: column.Header === "Decision" ? 4 : 1, md: column.Header === "Decision" ? 2 : 1, lg: 1 }}
                                            {...tHStyles}
                                        >
                                            <Flex {...flexSortStyles}>
                                                {t(column.render('Header'))}
                                                {column.isSorted === false || column.isSortedDesc === false ? (
                                                    <Box {...sortButtonStyles} opacity={column.isSorted === true && column.isSortedDesc === false ? 1 : 0.25}>
                                                        ▼
                                                    </Box>
                                                ) : (
                                                    <Box {...sortButtonStyles} opacity={column.isSorted === true && column.isSortedDesc === true ? 1 : 0.25}>
                                                        ▲
                                                    </Box>
                                                )}
                                            </Flex>
                                        </Th>
                                    ))}
                                    <Th {...tHActions} border="none">{t("Action")}</Th>
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody {...getTableBodyProps()} borderTop="none" borderColor="tableBorderColor">
                            {page.map((row, idx) => {
                                prepareRow(row);
                                return (
                                    <Fragment key={idx} >
                                        <Tr {...row.getRowProps()} {...tRBodyStyles} bgColor={idx % 2 === 0 ? "mainColor" : "secondaryBackgroundColor"} >
                                            {row.cells.map((cell, id) => (
                                                <Td key={id} {...mainTdStyles} pl={10} borderBottomLeftRadius={idx === page.length - 1 && id === 0 ? "30px" : 0} borderTopLeftRadius={idx === 0 && id === 0 ? "30px" : 0} pr={{ base: cell.column.Header === "Decision" ? 4 : 0, md: cell.column.Header === "Decision" ? 2 : 0, lg: 0 }}>{cell.render('Cell')}</Td>
                                            ))}
                                            <Td {...secondaryTdStyles} borderTopRightRadius={idx === 0 ? "30px" : 0} borderBottomRightRadius={idx === page.length - 1 ? "30px" : 0}>
                                                <Flex {...flexContainerStyles}>
                                                    <Link {...linkStyles} href={row.original.links.visit}>
                                                        <Button {...buttonVisitStyles}>{t("visit")}</Button>
                                                    </Link>
                                                    <Button {...buttonCopyStyles} onClick={() => goToCreateFormRoute(row)}>
                                                        {t("copy")}
                                                    </Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                        {row.isExpanded && checkIfExpanderIsVisible(row.cells) ? (
                                            <Tr>
                                                {renderRowSubComponent({ row })}
                                            </Tr>
                                        ) : null}
                                    </Fragment>
                                );
                            })}
                        </Tbody>
                    </Table>
                </div>
            </TableContainer>
            <br />
            <Flex {...outerFlexStyles}>
                <Flex {...columnFlexStyles}>
                    <Text {...showingTextStyles}>
                        {t("showing")} {((pageIndex) * pageSize) + 1} {t("to")} {getLowerNumber(rows.length, ((pageIndex + 1) * pageSize))} {t("of")} {rows.length} {t("decisions")}
                    </Text>
                    <Select {...selectStyles}
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[6, 8, 10, 15, 20, 50].map((pageSize, i) => (
                            <option key={i} value={pageSize}>
                                {t("show")} {pageSize}
                            </option>
                        ))}
                    </Select>
                </Flex>
                <Flex direction="row" align="center">
                    <Flex onClick={() => previousPage()} opacity={canPreviousPage ? 1 : 0.54} color={"secondaryColor"}
                        _hover={{ cursor: canPreviousPage ? "pointer" : "not-allowed", color: "secondaryColor" }}>
                        <Box mr={2} mt="11.5px">
                            <LeftArrow active={canPreviousPage} />
                        </Box>
                        <Text {...previousPageTextStyles}>
                            {t("previous")}
                        </Text>{" "}
                    </Flex>
                    <Flex direction="row" mt={-4}>
                        {getPaginationButtons(pageCount)}
                    </Flex>
                    <Flex direction="row" onClick={() => nextPage()} opacity={canNextPage ? 1 : 0.54} color={"secondaryColor"} _hover={{ cursor: canNextPage ? "pointer" : "not-allowed", color: "secondaryColor" }}>
                        <Text {...nextPageTextStyles}>
                            {t("next")}
                        </Text>{" "}
                        <Box ml={2} mt="11.5px">
                            <RightArrow active={canNextPage} />
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
}


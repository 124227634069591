const flexStyles = {
    pr: "66px",
    py: "6px",
    bgColor: "headerColor",
    justify: "space-between",
    w: "full",
    borderBottom: 0.1,
    borderColor: "headerBorder",
    fontFamily: "ubuntuFont",
    zIndex: 5,
};

const textStyles = {
    ml: 2,
    mt: 3,
    fontSize: 25,
    fontFamily: "interFont",
    color: "mainTextColor",
    fontWeight: 500,
};

const linkStyles = {
    mt: 4,
    mr: 4,
    color: "baseLinkColor",
    _hover: { textDecoration: "none", color: "secondaryColor" },
};

const menuButtonStyles = {
    align: "center",
    _hover: { cursor: "pointer" },
    pl: 4,
    pt: 2,
};


const menuListStyles = {
    bgColor: "mainColor",
    fontSize: 14,
    fontWeight: 400,
    color: "secondaryColor",
};

const boxStyles = {
    position: "relative",
};

const iconButtonStyles = {
    ariaLabel: "Open Menu",
    size: "lg",
    mr: 0,
    boxShadow: "lg",
    bgColor: "headerColor",
    _hover: { bgColor: "headerColor" },
    boxShadow: "none"
};

const logoImageStyles = {
    style: { width: 40 },
    id: "logo-image",
    src: "ethelo_logo.png",
    alt: "eth-logo",
    mr: 2
};

const avatarStyles = {
    mr: 3
};

const flexWithNameAndAvatarStyles = {
    align: "center",
    justifyContent: "center",
    gap: 3,
    mt: 3,
}

const boxWithUserName = {
    color: "secondaryColor",
    fontWeight: 500,
    mr: 4
}

const menuItemStyles = {
    width: "100%",
    bg: "transparent",
    _hover: { bg: "transparent" }
}

const boxWithDarkModeItems = {
    mt: 4,
    ml: 3,
    mr: 16,
    _hover: { cursor: "pointer" }
}

export { flexStyles, textStyles, linkStyles, menuButtonStyles, menuListStyles, boxStyles, iconButtonStyles, logoImageStyles, avatarStyles, flexWithNameAndAvatarStyles, boxWithUserName, menuItemStyles, boxWithDarkModeItems }
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Box, useDisclosure } from "@chakra-ui/react";
import Header from './components/Header';
import { useCurrentUserContext } from './features/user/contexts/user-provider';
import SidebarLayout from './components/SidebarLayout';
import Login from './pages/Login';
import Signup from './pages/Signup';
import RecoverPassword from './pages/RecoverPassword';
import DecisionCreated from './pages/DecisionCreated';
import Home from './pages/Home';
import Decisions from './pages/Decisions';
import CreateDecision from './pages/CreateDecision';
import CreateDecisionFormPage from './pages/CreateDecisionFormPage';
import { useCurrentDecisionsContext } from './features/decisions/contexts/decisions-provider';
import { ChakraProvider } from "@chakra-ui/react";
import { theme, darkTheme } from "./theme"

const App = () => {
    const { authorized } = useCurrentUserContext();
    const { getDataOfDecisions } = useCurrentDecisionsContext();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [lightMode, setLightMode] = useState(true);

    const changeBetweenLightAndDarkMode = () => {
        setLightMode((prevLightMode) => {
            const newLightMode = !prevLightMode;

            localStorage.setItem("lightMode", newLightMode);

            return newLightMode;
        });
    };

    const location = useLocation();

    useEffect(() => {
        const baseTitle = "Ethelo - ";

        const routeToTitle = {
            '/': 'Home',
            '/decisions-participant': 'Decisions Participant',
            '/decisions-host': 'Decisions Host',
            '/signup': 'Sign up',
            '/login': 'Login',
            '/recover-password': 'Recover Password',
            '/decisions': 'Decisions',
            '/create-decisions': "Create Decisions",
            '/create-decision-form': 'Create Decisions Form',
            "/decision-created": "Decision Created"
        };

        const currentTitle = routeToTitle[location.pathname] || 'No found';

        document.title = baseTitle + currentTitle;
    }, [location.pathname]);

    useEffect(() => {
        if (authorized) getDataOfDecisions()
    }, [authorized])

    useEffect(() => {
        const savedLightMode = localStorage.getItem("lightMode");
        if (savedLightMode) {
            setLightMode(savedLightMode === "true");
        }
    }, []);


    return (
        <ChakraProvider theme={lightMode ? theme : darkTheme}>
            <Box backgroundColor="mainBackgroundColor">
                <Box position="fixed" zIndex="5" w="full" mb={40}>
                    <Header onOpen={onOpen} changeBetweenLightAndDarkMode={changeBetweenLightAndDarkMode} lightMode={lightMode} />
                </Box>
                <Box pt={30}>
                    <Routes>
                        {authorized &&
                            <Route path="/" element={<SidebarLayout isOpen={isOpen} onClose={onClose} />}>
                                <Route path='/' element={<Home />} />
                                <Route path="/decisions" element={<Decisions />} />
                                <Route path="/create-decisions" element={<CreateDecision />} />
                                <Route path="/create-decision-form" element={<CreateDecisionFormPage />} />
                                <Route path="/decision-created" exact component={DecisionCreated} />
                            </Route>
                        }
                        {!authorized && <Route path="/signup" element={<Signup />} />}
                        {!authorized && <Route path="/recover-password" element={<RecoverPassword />} />}
                        {!authorized && <Route path="/login" element={<Login />} />}
                        {authorized && <Route path="*" element={<Navigate to="/" />} />}
                        {!authorized && <Route path="*" element={<Navigate to="login" />} />}
                    </Routes>
                </Box>
            </Box>
        </ChakraProvider>
    );
}
export default App;

import React, { useMemo, useCallback, Fragment, useState, useEffect } from 'react';
import { Link as RouterLink } from "react-router-dom"
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Flex,
    TableContainer,
    Button,
    Box,
    Text,
    Select,
    Link,
    Tooltip,
    Icon
} from '@chakra-ui/react';
import {
    useGlobalFilter,
    useSortBy,
    useTable,
    useExpanded,
    usePagination,
} from 'react-table';
import { FaEnvelope, FaEye } from 'react-icons/fa'
import GlobalFilter from 'components/GlobalFilter';
import FilterTableColumnsModal from 'components/FilterTableColumnsModal';
import { useCurrentDecisionsContext } from 'features/decisions/contexts/decisions-provider';
import HTMLReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import {
    flexWithFilterStyles,
    tableContainerStyles,
    tHeadStyles,
    tHStyles,
    flexSortStyles,
    sortButtonStyles,
    mainTdStyles,
    tHActions,
    secondaryTdStyles,
    flexContainerStyles,
    linkStyles,
    buttonStyles,
    outerFlexStyles,
    columnFlexStyles,
    showingTextStyles,
    pageNavigationTextStyles,
    previousPageTextStyles,
    selectStyles,
    nextPageTextStyles,
    tRHeadStyles,
    tRBodyStyles
} from "./styles"
import LeftArrow from 'images/leftArrow';
import RightArrow from 'images/rightArrow';

export default function TableOfActionsInvitedToParticipate() {
    const [t] = useTranslation('TableOfActionsInvitedToParticipate');

    const { tableDecisionsInvitedToParticipateData, fetchDataOfElectionsWhereUserIsInvitedToParticipate, convertDate } = useCurrentDecisionsContext()

    const { dataOfTable } = tableDecisionsInvitedToParticipateData

    const [tableState, setTableState] = useState(() => {
        const savedState = localStorage.getItem('tableInvitedToParticipateState');
        return savedState ? JSON.parse(savedState) : {};
    });

    const columns = useMemo(
        () => [
            {
                Header: "Decision",
                accessor: "title",
                id: 'expander',
                Cell: ({ row }) => (
                    <>
                        {dataOfTable[row.id]["current-mode"] !== "Closed" ?
                            <Flex gap={2} p={0} m={0} align="center" textAlign="left" color="baseLinkColor" _hover={{ cursor: "pointer" }}>
                                <Link as={RouterLink} _hover={{ textDecoration: "none", color: "secondaryColor" }} zIndex={2}>
                                    {dataOfTable[row.id].title}{" "}
                                </Link>
                                {dataOfTable[row.id].info && (
                                    <Box {...row.getToggleRowExpandedProps()} fontSize={14} color="mainTextColor" _hover={{ cursor: "pointer", color: "mainTextColor" }}>
                                        {row.isExpanded ? "(" + t("less") + ")" : "(" + t("more") + ")"}
                                    </Box>
                                )}
                            </Flex>
                            :
                            <Flex gap={2} p={0} m={0} align="center" textAlign="left">
                                <Box>{dataOfTable[row.id].title}{" "}</Box>
                                {dataOfTable[row.id].info && <Box {...row.getToggleRowExpandedProps()} fontSize={14}>
                                    {row.isExpanded ? "(" + t("less") + ")" : "(" + t("more") + ")"}
                                </Box>}
                            </Flex>}
                    </>
                ),
            },
            {
                Header: 'Group',
                accessor: 'group-name',
            },
            {
                Header: 'Status',
                accessor: 'current-mode'
            },
            {
                Header: "Open at",
                accessor: "open-at",
                Cell: ({ row }) => {
                    const dateFormatted = convertDate(dataOfTable[row.id]["open-at"])
                    return (
                        <>
                            {Array.isArray(dateFormatted) ?
                                <Box>
                                    <Box>{dateFormatted[0]} {dateFormatted[1]}</Box>
                                </Box> :
                                <Box>{dateFormatted}</Box>}
                        </>
                    )
                },
            },
            {
                Header: "Close at",
                accessor: "close-at",
                Cell: ({ row }) => {
                    const dateFormatted = convertDate(dataOfTable[row.id]["close-at"])
                    return (
                        <>
                            {Array.isArray(dateFormatted) ? <>
                                {dateFormatted[0]} {dateFormatted[1]}
                            </> : <>{dateFormatted}</>}
                        </>
                    )
                },
            },
        ],
        []
    );

    const ourGlobalFilterFunction = (rows, ids, query) => {
        return rows.filter(
            (row) =>
                (row.values["expander"] && query && row.values['expander'].toLowerCase().includes(query.toLowerCase())) ||
                (row.values["group"] && query && row.values['group'].toLowerCase().includes(query.toLowerCase())) ||
                (row.values["status"] && query && row.values['status'].toLowerCase().includes(query.toLowerCase()))
        );
    };

    const tableInstance = useTable(
        {
            columns: columns,
            data: dataOfTable,
            initialState: { ...tableState, pageIndex: 0, pageSize: 6 },
            globalFilter: ourGlobalFilterFunction,
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        state,
        allColumns,
        rows,
        getToggleHideAllColumnsProps,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        nextPage,
        gotoPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = tableInstance;

    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <Td colSpan={visibleColumns.length + 1} style={{ width: '100%', whiteSpace: 'pre-wrap' }} pr={2}>
                <Text fontSize={15} fontWeight={400}>
                    {HTMLReactParser(dataOfTable[row.id].info)}
                </Text>
            </Td>
        ),
        [dataOfTable]
    );

    const checkIfExpanderIsVisible = (columnsList) => {
        const columnExpander = columnsList.find(
            (currentColumn) => currentColumn.column.Header === 'Decision'
        );
        if (columnExpander) {
            return columnExpander.column.isVisible;
        }
        return false;
    };

    const getLowerNumber = (numberA, numberB) => {
        if (numberA < numberB) {
            return numberA
        } else {
            return numberB
        }
    }

    const saveTableStateToLocal = (tableState) => {
        localStorage.setItem('tableInvitedToParticipateState', JSON.stringify(tableState));
    };

    const getPaginationButtons = (pagesNumber) => {
        const paginationButtons = () => {
            const buttonList = [];
            for (let i = 0; i < pagesNumber; i++) {
                buttonList.push(<Button borderRadius="100%" _active={{ border: "1px", borderColor: "secondaryColor" }} _focus={{ outline: "none", borderColor: "secondaryColor" }} fontFamily="ubuntu" fontWeight={400} mr={1} size="xs" border="1px" borderColor="secondaryColor" color="secondaryColor" bgColor={pageIndex === i ? "rgba(8, 53, 86, 0.25)" : "transparent"} _hover={{ bgColor: "rgba(8, 53, 86, 0.25)" }} onClick={() => gotoPage(i)} key={i}>{i + 1}</Button>);
            }
            return buttonList;
        };

        return <>{paginationButtons()}</>;
    }

    useEffect(() => {
        saveTableStateToLocal(tableInstance.state);
        setTableState(tableInstance.state)
    }, [tableInstance.state]);

    return (
        <Box>
            <Table columns={columns} data={dataOfTable} pageCount={pageCount} renderRowSubComponent={renderRowSubComponent} />
            <Flex justify="space-between" align="center" pb={2}>
                <Flex direction="row" gap={4} align="bottom">
                    <FilterTableColumnsModal
                        getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                        allColumns={allColumns}
                    />
                    <Button onClick={() => fetchDataOfElectionsWhereUserIsInvitedToParticipate()} _active={{ bgColor: "buttonColor" }} bgColor="buttonColor" px={10} color="secondaryTextColor" borderRadius="15px" _hover={{ bgColor: "buttonColor", color: "secondaryTextColor" }} fontSize={16} fontWeight={400} fontFamily="interFont">{t("refresh")}</Button>
                </Flex>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    setGlobalFilter={setGlobalFilter}
                    globalFilter={state.globalFilter}
                    columns={columns}
                />
            </Flex>
            <TableContainer minH={150} borderRadius="7px" px={5} bgColor="secondaryBackgroundColor">
                <div className='scroll-container'>
                    <Table {...getTableProps()}>
                        <Thead pt={3} zIndex={3} position="sticky" top={0}>
                            {headerGroups.map((headerGroup, i) => (
                                <Tr {...headerGroup.getHeaderGroupProps()} key={i} style={{
                                    borderBottom: '2px solid borderColor',
                                    borderColor: 'borderColor',
                                }}>
                                    {headerGroup.headers.map((column, i) => (
                                        <Th
                                            key={i}
                                            color="secondaryColor"
                                            fontSize={15}
                                            fontWeight={700}
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            zIndex={4}
                                            pt={10}
                                            pl={1} pr={{ base: column.Header === "Decision" ? 4 : 1, md: column.Header === "Decision" ? 2 : 1, lg: 1 }}
                                        >
                                            <Flex
                                                direction="row"
                                                align="center"
                                                zIndex={3}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {t(column.render('Header'))}
                                                {column.isSorted === false || column.isSortedDesc === false ? (
                                                    <Box color="borderColor" ml={2} _hover={{ opacity: 1 }} opacity={column.isSorted === true && column.isSortedDesc === false ? 1 : 0.25}>
                                                        ▼
                                                    </Box>
                                                ) : (
                                                    <Box ml={2} color="borderColor" _hover={{ opacity: 1 }} opacity={column.isSorted === true && column.isSortedDesc === true ? 1 : 0.25}>
                                                        ▲
                                                    </Box>
                                                )}
                                            </Flex>
                                        </Th>
                                    ))}
                                    <Th color="secondaryColor" pl={1} pr={1} pt={10} fontSize={15} fontWeight={700}>
                                        {t("Action")}
                                    </Th>
                                </Tr>
                            ))}
                            {!headerGroups.length ?
                                <Tr style={{
                                    borderBottom: '2px solid borderColor',
                                    borderColor: 'borderColor',
                                }}>
                                    <Th color="secondaryColor" pl={5} pr={1} pt={10} fontSize={15} fontWeight={700} border="none">{t("Action")}</Th>
                                </Tr>
                                : null}
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {page.map((row, idx) => {
                                prepareRow(row);
                                return (
                                    <Fragment key={idx}>
                                        <Tr {...row.getRowProps()} bgColor={idx % 2 === 0 ? "mainColor" : "secondaryBackgroundColor"} style={{
                                            borderTop: '1.5px solid borderColor',
                                            borderColor: 'borderColor',
                                        }}>
                                            {row.cells.map((cell, id) => (
                                                <Td key={id} textAlign="left" {...cell.getCellProps()} borderTopLeftRadius={idx === 0 && id === 0 ? "30px" : 0} borderBottomLeftRadius={idx === page.length - 1 && id === 0 ? "30px" : 0} fontWeight={500} style={{ maxHeight: 100, whiteSpace: "normal", overflowWrap: "break-spaces" }} pl={6} pr={{ base: cell.column.Header === "Decision" ? 4 : 1, md: cell.column.Header === "Decision" ? 2 : 1, lg: 1 }}>{cell.render('Cell')}</Td>
                                            ))}
                                            {(row.original["current-mode"] !== 'Coming Soon' && row.original.status !== 'Closed') && (
                                                row.original["current-mode"] === "Open" ?
                                                    <Td pl={1} pr={1} style={{ maxWidth: 100, whiteSpace: "normal", overflowWrap: "break-spaces" }} borderTopRightRadius={idx === 0 ? "30px" : 0} borderBottomRightRadius={idx === page.length - 1 ? "30px" : 0}>
                                                        <Flex align="center" justify="left" gap={2} flexWrap="wrap" pl={4}>
                                                            <Tooltip label={t("join-to-vote")} fontSize='md' style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
                                                                <Link _hover={{ textDecoration: "none" }} href={row.original.links && row.original.links.participate ? row.original.links.participate : ""} >
                                                                    <Icon as={FaEnvelope} />
                                                                </Link>
                                                            </Tooltip>
                                                        </Flex>
                                                    </Td> :
                                                    <Td pl={1} pr={1} style={{ maxWidth: 100, whiteSpace: "normal", overflowWrap: "break-spaces" }} borderTopRightRadius={idx === 0 ? "30px" : 0} borderBottomRightRadius={idx === page.length - 1 ? "30px" : 0}>
                                                        <Flex align="center" justify="left" gap={2} flexWrap="wrap" pl={4}>
                                                            <Tooltip label={t("join-to-view")} fontSize='md' style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
                                                                <Link _hover={{ textDecoration: "none" }} href={row.original.links && row.original.links.results ? row.original.links.results : ""}>
                                                                    <Icon as={FaEye} />
                                                                </Link>
                                                            </Tooltip>
                                                        </Flex>
                                                    </Td>
                                            )}
                                        </Tr>
                                        {row.isExpanded && checkIfExpanderIsVisible(row.cells) ? (
                                            <Tr>
                                                {renderRowSubComponent({ row })}
                                            </Tr>
                                        ) : null}
                                    </Fragment>
                                );
                            })}
                        </Tbody>
                    </Table>
                </div>
            </TableContainer>
            <br />
            <Box></Box>
            <Flex {...outerFlexStyles}>
                <Flex {...columnFlexStyles}>
                    <Text {...showingTextStyles}>
                        {t("showing")} {((pageIndex) * pageSize) + 1} {t("to")} {getLowerNumber(rows.length, ((pageIndex + 1) * pageSize))} {t("of")} {rows.length} {t("decisions")}
                    </Text>
                    <Select {...selectStyles}
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[6, 8, 10, 15, 20, 50].map((pageSize, i) => (
                            <option key={i} value={pageSize}>
                                {t("show")} {pageSize}
                            </option>
                        ))}
                    </Select>
                </Flex>
                <Flex direction="row" align="center">
                    <Flex onClick={() => previousPage()} opacity={canPreviousPage ? 1 : 0.54} color={"secondaryColor"}
                        _hover={{ cursor: canPreviousPage ? "pointer" : "not-allowed", color: "secondaryColor" }}>
                        <Box mr={2} mt="11.5px">
                            <LeftArrow active={canPreviousPage} />
                        </Box>
                        <Text {...previousPageTextStyles}>
                            {t("previous")}
                        </Text>{" "}
                    </Flex>
                    <Flex direction="row" mt={-4}>
                        {getPaginationButtons(pageCount)}
                    </Flex>
                    <Flex direction="row" onClick={() => nextPage()} opacity={canNextPage ? 1 : 0.54} color={"secondaryColor"} _hover={{ cursor: canNextPage ? "pointer" : "not-allowed", color: "secondaryColor" }}>
                        <Text {...nextPageTextStyles}>
                            {t("next")}
                        </Text>{" "}
                        <Box ml={2} mt="11.5px">
                            <RightArrow active={canNextPage} />
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
}

const flexWithFilterStyles = {
    justify: "flex-end",
    align: "center",
    pb: 2
};

const tableContainerStyles = {
    minH: 150,
    borderRadius: "7px",
    px: 5,
    bg: "mainColor"
};

const tHeadStyles = {
    pt: 3,
    zIndex: 3,
    bg: "mainColor",
    position: "sticky",
    top: 0
};

const tHStyles = {
    zIndex: 4,
    pt: 10,
    w: "17%",
    pl: 1,
    color: "secondaryColor",
    fontSize: 15,
    fontWeight: 700
};

const flexSortStyles = {
    direction: "row",
    align: "center",
    zIndex: 3,
    style: { cursor: "pointer" }
};

const sortButtonStyles = {
    color: "borderColor",
    ml: 2,
    _hover: { opacity: 1 }
};

const tHActions = {
    color: "secondaryColor",
    w: "49%",
    pl: 1,
    pr: "180px",
    pt: 10,
    fontSize: 15,
    fontWeight: 700,
    textAlign: "right"
};

const tRHeadStyles = {
    bt: '2.5px solid borderColor',
    bColor: 'borderColor',
};

const tRBodyStyles = {
    style: {
        borderBottom: '2px solid borderColor',
        borderColor: 'borderColor',
    },
    px: 0
}

const mainTdStyles = {
    w: "17%",
    textAlign: "left",
    fontWeight: 500,
    pl: 0,
    style: {
        maxHeight: 100,
        whiteSpace: "normal",
        overflowWrap: "break-spaces"
    }
};

const secondaryTdStyles = {
    pl: 1,
    pr: 1,
    style: {
        maxWidth: 100,
        whiteSpace: "normal",
        overflowWrap: "break-spaces"
    }
};

const flexContainerStyles = {
    align: "right",
    w: "100%",
    gap: 2,
    justify: "flex-end"
};

const linkStyles = {
    _hover: { textDecoration: "none" },
    target: '_blank',
    //href: row.original.links.visit
};

const buttonStyles = {
    _active: { bgColor: "secondaryButtonColor" },
    bgColor: "secondaryButtonColor",
    px: 10,
    color: "secondaryTextColor",
    borderRadius: "15px",
    _hover: { bgColor: "secondaryButtonColor", color: "secondaryTextColor" },
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "interFont",
    mr: 2
};

const outerFlexStyles = {
    justify: "space-between"
};

const columnFlexStyles = {
    direction: "column"
};

const showingTextStyles = {
    fontSize: 15,
    fontWeight: 500,
    fontFamily: "interFont"
};

const pageNavigationTextStyles = {
    fontSize: 15,
    fontWeight: 500,
    fontFamily: "interFont"
};

const previousPageTextStyles = {
    mt: 1,
    mr: 3,
    /*color: canPreviousPage ? "baseLinkColor" : "mainTextColor",
    _hover: {
        cursor: canPreviousPage ? "pointer" : "not-allowed",
        color: canPreviousPage ? "secondaryColor" : "mainTextColor"
    }*/
};

const selectStyles = {
    fontSize: 15,
    fontWeight: 500
};

const nextPageTextStyles = {
    mt: 1,
    ml: 3,
    /*color: canNextPage ? "baseLinkColor" : "mainTextColor",
    _hover: {
        cursor: canNextPage ? "pointer" : "not-allowed",
        color: canNextPage ? "secondaryColor" : "mainTextColor
}*/
};

export {
    flexWithFilterStyles,
    tableContainerStyles,
    tHeadStyles,
    tHStyles,
    flexSortStyles,
    sortButtonStyles,
    tHActions,
    mainTdStyles,
    secondaryTdStyles,
    flexContainerStyles,
    linkStyles,
    buttonStyles,
    outerFlexStyles,
    columnFlexStyles,
    showingTextStyles,
    pageNavigationTextStyles,
    previousPageTextStyles,
    selectStyles,
    nextPageTextStyles,
    tRHeadStyles,
    tRBodyStyles
};

import React from 'react'
import { Box, Grid, GridItem, Button, Link, Text } from '@chakra-ui/react'
import { useCurrentDecisionsContext } from 'features/decisions/contexts/decisions-provider';
import { Link as RouterLink } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { headerStyles, buttonStyles } from "./styles"

export default function DecisionCreated() {
    const { editDecisionUrl } = useCurrentDecisionsContext()
    const [t] = useTranslation('DecisionCreated');

    return (
        <Box pt={32}>
            <Grid templateColumns='repeat(11, 1fr)'>
                <GridItem colSpan={5} px={4}>
                    <Text {...headerStyles}>{t("decision-ready-for-editing")}</Text>
                    <Text mb={3}>{t("decision-ready-click-below")}</Text>
                    <Link href={editDecisionUrl.url} target='_blank'>
                        <Button {...buttonStyles} fontSize={13} fontWeight={400} >
                            {t("edit-decision")}
                        </Button>
                    </Link>
                </GridItem>
                <GridItem colSpan={6}>
                    <Link as={RouterLink} to="/create-decisions" _hover={{ textDecoration: "none" }}>
                        <Button {...buttonStyles} fontSize={16} fontWeight={400}>
                            {'<'} {t("go-back")}
                        </Button>
                    </Link>
                </GridItem>
            </Grid>
        </Box>
    )
}



import { useState, useEffect } from 'react';
import { useCurrentDecisionsContext } from 'features/decisions/contexts/decisions-provider';
import TemplateCard from 'components/TemplateCard';
import { Grid, GridItem, Box, Input, Text, Flex, Select, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
    outerFlexStyles,
    columnFlexStyles,
    showingTextStyles,
    previousPageTextStyles,
    selectStyles,
    nextPageTextStyles,
} from "./styles"
import LeftArrow from 'images/leftArrow';
import RightArrow from 'images/rightArrow';

export default function TemplatesList({ textToFilter }) {
    const { decisionsTemplates } = useCurrentDecisionsContext()
    const { dataOfTemplates } = decisionsTemplates
    const [templatesAfterFilter, setTemplatesAfterFilter] = useState(dataOfTemplates)
    const [pageIndex, setPageIndex] = useState(0)
    const listOfPagesSizes = [6, 8, 10, 15, 20, 50]
    const [pageSize, setPageSize] = useState(listOfPagesSizes[0])
    const [templatesToShowWithPagination, setTemplatesToShowWithPagination] = useState(dataOfTemplates)
    const [t] = useTranslation('TemplatesList');

    const getLowerNumber = (numberA, numberB) => {
        if (numberA < numberB) {
            return numberA
        } else {
            return numberB
        }
    }

    const previousPage = () => {
        if (pageIndex <= 0) return
        setPageIndex(pageIndex - 1)
    }

    const nextPage = () => {
        if (templatesAfterFilter.length <= ((pageIndex + 1) * pageSize)) return
        setPageIndex(pageIndex + 1)
    }

    const getPaginationButtons = (pagesNumber) => {
        const paginationButtons = () => {
            const buttonList = [];
            for (let i = 0; i < pagesNumber; i++) {
                buttonList.push(<Button borderRadius="100%" _active={{ border: "1px", borderColor: "secondaryColor" }} _focus={{ outline: "none", borderColor: "secondaryColor" }} fontFamily="ubuntu" fontWeight={400} mr={1} size="xs" border="1px" borderColor="secondaryColor" color="secondaryColor" bgColor={pageIndex === i ? "rgba(8, 53, 86, 0.25)" : "transparent"} _hover={{ bgColor: "rgba(8, 53, 86, 0.25)" }} onClick={() => setPageIndex(i)} key={i}>{i + 1}</Button>);
            }
            return buttonList;
        };

        return <>{paginationButtons()}</>;
    }

    useEffect(() => {
        const newTemplatesAfterFilter = dataOfTemplates.filter(currentTemplate => currentTemplate.title.toLowerCase().includes(textToFilter.toLowerCase()))
        setTemplatesAfterFilter(newTemplatesAfterFilter)
        setPageIndex(0)
        const positionOfFirstTemplateToShow = 0
        let positionOfLastTemplateToShow = getLowerNumber(newTemplatesAfterFilter.length, ((pageIndex + 1) * pageSize))

        const newTemplatesToShow = newTemplatesAfterFilter.slice(positionOfFirstTemplateToShow, positionOfLastTemplateToShow)

        setTemplatesToShowWithPagination(newTemplatesToShow)
    }, [textToFilter])

    useEffect(() => {
        setPageIndex(0)
    }, [pageSize])

    useEffect(() => {
        const positionOfFirstTemplateToShow = pageIndex * pageSize
        let positionOfLastTemplateToShow = getLowerNumber(templatesAfterFilter.length, ((pageIndex + 1) * pageSize))

        const newTemplatesToShow = templatesAfterFilter.slice(positionOfFirstTemplateToShow, positionOfLastTemplateToShow)

        setTemplatesToShowWithPagination(newTemplatesToShow)
    }, [pageIndex])

    return (
        <Box backgroundColor="secondaryBackgroundColor" pt={12}>
            <Grid templateColumns={{ base: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} justifyContent="center" px="12" pb="8">
                {templatesToShowWithPagination?.map((decisionTemplate, i) => (
                    <GridItem mb={3} key={i}>
                        <TemplateCard decisionTemplate={decisionTemplate} />
                    </GridItem>
                ))}
            </Grid>
            <Flex {...outerFlexStyles}>
                <Flex {...columnFlexStyles}>
                    <Text {...showingTextStyles}>
                        {t("showing")} {((pageIndex) * pageSize) + 1} {t("to")} {getLowerNumber(templatesAfterFilter.length, ((pageIndex + 1) * pageSize))} {t("of")} {templatesAfterFilter.length} {t("templates")}
                    </Text>
                    <Select {...selectStyles}
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {listOfPagesSizes.map((pageSize, i) => (
                            <option key={i} value={pageSize}>
                                {t("show")} {pageSize}
                            </option>
                        ))}
                    </Select>
                </Flex>
                <Flex direction="row" align="center">
                    <Flex onClick={() => previousPage()} opacity={pageIndex > 0 ? 1 : 0.54} color={"secondaryColor"}
                        _hover={{ cursor: pageIndex > 0 ? "pointer" : "not-allowed", color: "secondaryColor" }}>
                        <Box mr={2} mt="11.5px">
                            <LeftArrow active={pageIndex > 0} />
                        </Box>
                        <Text {...previousPageTextStyles}>
                            {t("previous")}
                        </Text>{" "}
                    </Flex>
                    <Flex direction="row" mt={-4}>
                        {getPaginationButtons(Math.ceil(templatesAfterFilter.length / pageSize))}
                    </Flex>
                    <Flex direction="row" onClick={() => nextPage()} opacity={pageIndex < templatesAfterFilter.length - 1 ? 1 : 0.54} color={"secondaryColor"} _hover={{ cursor: pageIndex < templatesAfterFilter.length - 1 ? "pointer" : "not-allowed", color: "secondaryColor" }}>
                        <Text {...nextPageTextStyles}>
                            {t("next")}
                        </Text>{" "}
                        <Box ml={2} mt="11.5px">
                            <RightArrow active={pageIndex < templatesAfterFilter.length - 1} />
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
}